/* tslint:disable */
/* eslint-disable */
/**
 * My App API
 * My App API description
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { GoalResponse } from './GoalResponse';
import {
  GoalResponseFromJSON,
  GoalResponseFromJSONTyped,
  GoalResponseToJSON,
  GoalResponseToJSONTyped,
} from './GoalResponse';

/**
 *
 * @export
 * @interface GoalListResponse
 */
export interface GoalListResponse {
  /**
   *
   * @type {Array<GoalResponse>}
   * @memberof GoalListResponse
   */
  items: Array<GoalResponse>;
}

/**
 * Check if a given object implements the GoalListResponse interface.
 */
export function instanceOfGoalListResponse(value: object): value is GoalListResponse {
  if (!('items' in value) || value['items'] === undefined) return false;
  return true;
}

export function GoalListResponseFromJSON(json: any): GoalListResponse {
  return GoalListResponseFromJSONTyped(json, false);
}

export function GoalListResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GoalListResponse {
  if (json == null) {
    return json;
  }
  return {
    items: (json['items'] as Array<any>).map(GoalResponseFromJSON),
  };
}

export function GoalListResponseToJSON(json: any): GoalListResponse {
  return GoalListResponseToJSONTyped(json, false);
}

export function GoalListResponseToJSONTyped(
  value?: GoalListResponse | null,
  ignoreDiscriminator: boolean = false,
): any {
  if (value == null) {
    return value;
  }

  return {
    items: (value['items'] as Array<any>).map(GoalResponseToJSON),
  };
}
