import type { GoalResponse } from '../../client';
import { formatDate } from '../../utils.ts';

export interface GoalProgress {
  progress: number;
  title: string;
  subtitle: string;
}

export function getGoalProgress(goal: GoalResponse): GoalProgress {
  const currentPeriod = goal.periods[0];
  const currentPeriodProgress = currentPeriod?.progress || 0;
  const currentPeriodTotal = currentPeriod?.total || 0;

  switch (goal.type) {
    case 'project_completion':
      return {
        progress: goal.completed ? 100 : 0,
        subtitle: goal.completed ? 'Completed' : 'Not completed yet',
        title: goal.targetDate
          ? `Finish the project "${goal.target}" by ${formatDate(goal.targetDate)}`
          : `Finish the project "${goal.target}"`,
      };
    case 'word_count':
      return {
        progress: currentPeriodProgress,
        subtitle: `${currentPeriodTotal}/${goal.target} words so far ${convertToCurrentTimeRange(goal.recurrence)}`,
        title: `Write ${goal.target} words per ${convertToRecurrence(goal.recurrence)}`,
      };
    case 'entry_count':
      return {
        progress: currentPeriodProgress,
        subtitle: `${currentPeriodTotal}/${goal.target} entries so far ${convertToCurrentTimeRange(goal.recurrence)}`,
        title: `Write ${goal.target} entries per ${convertToRecurrence(goal.recurrence)}`,
      };
    default:
      // TODO
      return {
        progress: 0,
        subtitle: '',
        title: '',
      };
  }
}

export function convertToRecurrence(recurrence: string): string {
  if (recurrence === 'daily') {
    return 'day';
  }

  if (recurrence === 'weekly') {
    return 'week';
  }

  if (recurrence === 'monthly') {
    return 'month';
  }

  // TODO
  return '';
}

export function convertToCurrentTimeRange(recurrence: string): string {
  if (recurrence === 'daily') {
    return 'today';
  }

  if (recurrence === 'weekly') {
    return 'this week';
  }

  if (recurrence === 'monthly') {
    return 'this month';
  }

  // TODO
  return '';
}
