import {
  ArrowUturnLeftIcon,
  ArrowUturnRightIcon,
  BoldIcon,
  ChevronDownIcon,
  ItalicIcon,
  QuestionMarkCircleIcon,
} from '@heroicons/react/24/solid';
import { Box, Loader, Skeleton, Text } from '@mantine/core';
import { useForm } from '@mantine/form';
import { notifications } from '@mantine/notifications';
import {
  BlockTypeSelect,
  BoldItalicUnderlineToggles,
  type IconKey,
  MDXEditor,
  type MDXEditorMethods,
  Separator,
  UndoRedo,
  headingsPlugin,
  listsPlugin,
  quotePlugin,
  toolbarPlugin,
} from '@mdxeditor/editor';
import pluralize from 'pluralize';
import type React from 'react';
import { useEffect, useRef } from 'react';
import { useEntry } from './EntryContext.tsx';
import classes from './EntryEdit.module.css';
import useAutoSave from './useAutoSave.ts';

const MdxIcon = ({ Icon }: { Icon: React.ElementType }) => {
  return (
    <Box p={4}>
      <Icon width={18} />
    </Box>
  );
};

function getIconForMdx(icon: IconKey): JSX.Element {
  switch (icon) {
    case 'arrow_drop_down':
      return <MdxIcon Icon={ChevronDownIcon} />;
    case 'format_bold':
      return <MdxIcon Icon={BoldIcon} />;
    case 'format_italic':
      return <MdxIcon Icon={ItalicIcon} />;
    case 'redo':
      return <MdxIcon Icon={ArrowUturnRightIcon} />;
    case 'undo':
      return <MdxIcon Icon={ArrowUturnLeftIcon} />;
    default:
      console.warn(`No icon found for ${icon}`);
      return <MdxIcon Icon={QuestionMarkCircleIcon} />;
  }
}

export default function EntryContent() {
  const { entry, entryLoading, entrySaving, setEntryContent } = useEntry();
  const editorRef = useRef<MDXEditorMethods>(null);

  const { initialize, ...form } = useForm({
    mode: 'controlled',
    initialValues: {
      content: entry?.content || '',
    },
  });

  // Ensure we're auto-saving the content regularly, and when certain browser events occur
  useAutoSave(form.values.content, setEntryContent);

  useEffect(() => {
    if (entry && !form.initialized) {
      editorRef.current?.setMarkdown(entry.content);
      initialize({
        content: entry.content,
      });
    }
  }, [entry, form.initialized, initialize]);

  const contentInputProps = form.getInputProps('content');
  const content = form.values.content || '';
  const wordCount = content.trim().length === 0 ? 0 : content.split(/\s+\b/).length;

  if (entryLoading) {
    return (
      <Box p="md">
        <Skeleton height={12} radius="xl" />
        <Skeleton height={12} mt={6} radius="xl" />
        <Skeleton height={12} mt={6} radius="xl" />
        <Skeleton height={12} mt={6} radius="xl" />
        <Skeleton height={12} mt={6} radius="xl" />
        <Skeleton height={12} mt={6} width="70%" radius="xl" />
      </Box>
    );
  }

  const savingElement = entrySaving ? (
    <Loader size={18} />
  ) : entry?.updatedAt ? (
    <Text size="sm">Saved at {entry.updatedAt.toLocaleTimeString()}</Text>
  ) : (
    <Text size="sm">Not saved yet</Text>
  );

  return (
    <MDXEditor
      contentEditableClassName={classes.prose}
      key={form.key('content')}
      autoFocus
      iconComponentFor={getIconForMdx}
      placeholder="Start writing something..."
      trim
      markdown={contentInputProps.value || ''}
      onChange={contentInputProps.onChange}
      onBlur={contentInputProps.onBlur}
      onError={(payload) => {
        console.error(payload);

        notifications.show({
          autoClose: false,
          title: 'Error',
          message:
            "There is a problem with this entry's content, and further changes may not be saved. Please contact support@writual.app",
          color: 'red',
        });
      }}
      plugins={[
        headingsPlugin(),
        listsPlugin(),
        quotePlugin(),
        toolbarPlugin({
          toolbarClassName: classes.toolbar,
          toolbarContents: () => (
            <>
              <UndoRedo />
              <BoldItalicUnderlineToggles options={['Bold', 'Italic']} />
              <BlockTypeSelect />

              <Box ml="auto">
                <Text size="sm">
                  {wordCount} {pluralize('word', wordCount)}
                </Text>
              </Box>
              <Separator />
              <Box mr={8}>{savingElement}</Box>
            </>
          ),
        }),
      ]}
      ref={editorRef}
    />
  );
}
