/* tslint:disable */
/* eslint-disable */
/**
 * My App API
 * My App API description
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { EntrySeries } from './EntrySeries';
import {
  EntrySeriesFromJSON,
  EntrySeriesFromJSONTyped,
  EntrySeriesToJSON,
  EntrySeriesToJSONTyped,
} from './EntrySeries';
import type { EntryTag } from './EntryTag';
import { EntryTagFromJSON, EntryTagFromJSONTyped, EntryTagToJSON, EntryTagToJSONTyped } from './EntryTag';

/**
 *
 * @export
 * @interface EntryListResponseItem
 */
export interface EntryListResponseItem {
  /**
   *
   * @type {number}
   * @memberof EntryListResponseItem
   */
  id: number;
  /**
   *
   * @type {string}
   * @memberof EntryListResponseItem
   */
  title: string;
  /**
   *
   * @type {Date}
   * @memberof EntryListResponseItem
   */
  date: Date;
  /**
   *
   * @type {string}
   * @memberof EntryListResponseItem
   */
  type: string;
  /**
   *
   * @type {string}
   * @memberof EntryListResponseItem
   */
  extract: string;
  /**
   *
   * @type {number}
   * @memberof EntryListResponseItem
   */
  wordCount: number;
  /**
   *
   * @type {number}
   * @memberof EntryListResponseItem
   */
  charCount: number;
  /**
   *
   * @type {Date}
   * @memberof EntryListResponseItem
   */
  createdAt: Date;
  /**
   *
   * @type {Date}
   * @memberof EntryListResponseItem
   */
  updatedAt: Date;
  /**
   *
   * @type {Array<EntryTag>}
   * @memberof EntryListResponseItem
   */
  tags: Array<EntryTag>;
  /**
   *
   * @type {EntrySeries}
   * @memberof EntryListResponseItem
   */
  series: EntrySeries;
  /**
   *
   * @type {string}
   * @memberof EntryListResponseItem
   */
  visibility: EntryListResponseItemVisibilityEnum;
}

/**
 * @export
 */
export const EntryListResponseItemVisibilityEnum = {
  Private: 'private',
  Public: 'public',
} as const;
export type EntryListResponseItemVisibilityEnum =
  (typeof EntryListResponseItemVisibilityEnum)[keyof typeof EntryListResponseItemVisibilityEnum];

/**
 * Check if a given object implements the EntryListResponseItem interface.
 */
export function instanceOfEntryListResponseItem(value: object): value is EntryListResponseItem {
  if (!('id' in value) || value['id'] === undefined) return false;
  if (!('title' in value) || value['title'] === undefined) return false;
  if (!('date' in value) || value['date'] === undefined) return false;
  if (!('type' in value) || value['type'] === undefined) return false;
  if (!('extract' in value) || value['extract'] === undefined) return false;
  if (!('wordCount' in value) || value['wordCount'] === undefined) return false;
  if (!('charCount' in value) || value['charCount'] === undefined) return false;
  if (!('createdAt' in value) || value['createdAt'] === undefined) return false;
  if (!('updatedAt' in value) || value['updatedAt'] === undefined) return false;
  if (!('tags' in value) || value['tags'] === undefined) return false;
  if (!('series' in value) || value['series'] === undefined) return false;
  if (!('visibility' in value) || value['visibility'] === undefined) return false;
  return true;
}

export function EntryListResponseItemFromJSON(json: any): EntryListResponseItem {
  return EntryListResponseItemFromJSONTyped(json, false);
}

export function EntryListResponseItemFromJSONTyped(json: any, ignoreDiscriminator: boolean): EntryListResponseItem {
  if (json == null) {
    return json;
  }
  return {
    id: json['id'],
    title: json['title'],
    date: new Date(json['date']),
    type: json['type'],
    extract: json['extract'],
    wordCount: json['wordCount'],
    charCount: json['charCount'],
    createdAt: new Date(json['createdAt']),
    updatedAt: new Date(json['updatedAt']),
    tags: (json['tags'] as Array<any>).map(EntryTagFromJSON),
    series: EntrySeriesFromJSON(json['series']),
    visibility: json['visibility'],
  };
}

export function EntryListResponseItemToJSON(json: any): EntryListResponseItem {
  return EntryListResponseItemToJSONTyped(json, false);
}

export function EntryListResponseItemToJSONTyped(
  value?: EntryListResponseItem | null,
  ignoreDiscriminator: boolean = false,
): any {
  if (value == null) {
    return value;
  }

  return {
    id: value['id'],
    title: value['title'],
    date: value['date'].toISOString().substring(0, 10),
    type: value['type'],
    extract: value['extract'],
    wordCount: value['wordCount'],
    charCount: value['charCount'],
    createdAt: value['createdAt'].toISOString(),
    updatedAt: value['updatedAt'].toISOString(),
    tags: (value['tags'] as Array<any>).map(EntryTagToJSON),
    series: EntrySeriesToJSON(value['series']),
    visibility: value['visibility'],
  };
}
