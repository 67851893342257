/* tslint:disable */
/* eslint-disable */
/**
 * My App API
 * My App API description
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 *
 * @export
 * @interface EntrySeries
 */
export interface EntrySeries {
  /**
   *
   * @type {number}
   * @memberof EntrySeries
   */
  id: number;
  /**
   *
   * @type {string}
   * @memberof EntrySeries
   */
  name: string;
}

/**
 * Check if a given object implements the EntrySeries interface.
 */
export function instanceOfEntrySeries(value: object): value is EntrySeries {
  if (!('id' in value) || value['id'] === undefined) return false;
  if (!('name' in value) || value['name'] === undefined) return false;
  return true;
}

export function EntrySeriesFromJSON(json: any): EntrySeries {
  return EntrySeriesFromJSONTyped(json, false);
}

export function EntrySeriesFromJSONTyped(json: any, ignoreDiscriminator: boolean): EntrySeries {
  if (json == null) {
    return json;
  }
  return {
    id: json['id'],
    name: json['name'],
  };
}

export function EntrySeriesToJSON(json: any): EntrySeries {
  return EntrySeriesToJSONTyped(json, false);
}

export function EntrySeriesToJSONTyped(value?: EntrySeries | null, ignoreDiscriminator: boolean = false): any {
  if (value == null) {
    return value;
  }

  return {
    id: value['id'],
    name: value['name'],
  };
}
