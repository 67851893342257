/* tslint:disable */
/* eslint-disable */
/**
 * My App API
 * My App API description
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 *
 * @export
 * @interface EntryListResponseMeta
 */
export interface EntryListResponseMeta {
  /**
   *
   * @type {number}
   * @memberof EntryListResponseMeta
   */
  entriesTotal: number;
  /**
   *
   * @type {number}
   * @memberof EntryListResponseMeta
   */
  entriesWords: number;
}

/**
 * Check if a given object implements the EntryListResponseMeta interface.
 */
export function instanceOfEntryListResponseMeta(value: object): value is EntryListResponseMeta {
  if (!('entriesTotal' in value) || value['entriesTotal'] === undefined) return false;
  if (!('entriesWords' in value) || value['entriesWords'] === undefined) return false;
  return true;
}

export function EntryListResponseMetaFromJSON(json: any): EntryListResponseMeta {
  return EntryListResponseMetaFromJSONTyped(json, false);
}

export function EntryListResponseMetaFromJSONTyped(json: any, ignoreDiscriminator: boolean): EntryListResponseMeta {
  if (json == null) {
    return json;
  }
  return {
    entriesTotal: json['entriesTotal'],
    entriesWords: json['entriesWords'],
  };
}

export function EntryListResponseMetaToJSON(json: any): EntryListResponseMeta {
  return EntryListResponseMetaToJSONTyped(json, false);
}

export function EntryListResponseMetaToJSONTyped(
  value?: EntryListResponseMeta | null,
  ignoreDiscriminator: boolean = false,
): any {
  if (value == null) {
    return value;
  }

  return {
    entriesTotal: value['entriesTotal'],
    entriesWords: value['entriesWords'],
  };
}
