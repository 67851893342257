import { useQuery } from '@tanstack/react-query';
import pluralize from 'pluralize';
import api from '../../api';
import type { StatsResponse } from '../../client';
import ErrorScreen from '../ErrorScreen.tsx';
import LoadingScreen from '../LoadingScreen';
import Page from '../Page';
import PageTitle from '../PageTitle';

const StatsScreen = () => {
  const query = useQuery<StatsResponse>({
    queryKey: ['api', 'v1', 'stats'],
    queryFn: () => api.getStats(),
  });

  if (query.isLoading) {
    return <LoadingScreen />;
  }

  if (query.isError) {
    return <ErrorScreen error={query.error} />;
  }

  const stats = query.data;
  if (!stats) {
    return <div>There are no stats</div>;
  }

  const { currentStreak, longestStreak, totalEntries, totalWords, shortestEntry, longestEntry } = stats;

  return (
    <Page title={<PageTitle title="Stats" />}>
      <div>
        <h5>Current streak</h5>

        {currentStreak ? (
          <p>
            {currentStreak} {pluralize('day', currentStreak)}
          </p>
        ) : (
          <p>0 days</p>
        )}
      </div>

      <div>
        <h5>Longest streak</h5>
        {longestStreak ? (
          <p>
            {longestStreak} {pluralize('day', longestStreak)}
          </p>
        ) : (
          <p>0 days</p>
        )}
      </div>

      <div>
        <h5>Total entries</h5>
        <p>{totalEntries}</p>
      </div>
      <div>
        <h5>Total words</h5>
        <p>{totalWords}</p>
      </div>

      {/* TODO: There might be no entries */}
      <div>
        <h5>Shortest entry</h5>
        <p>
          {/*TODO*/}
          {shortestEntry == null ? <span>No entries yet!</span> : <>({shortestEntry} characters)</>}

          {/*{*/}
          {/*    shortest_entry*/}
          {/*        ? <><a*/}
          {/*            href={ `/entries/${ shortest_entry.date }` }>{ shortest_entry.date }</a> ({ shortest_entry.char_count } characters)</>*/}
          {/*        : <span>No entries yet!</span>*/}
          {/*}*/}
        </p>
      </div>
      <div>
        <h5>Longest entry</h5>
        <p>
          {/*TODO*/}

          {longestEntry == null ? <span>No entries yet!</span> : <>({longestEntry} characters)</>}

          {/*{*/}
          {/*    longest_entry*/}
          {/*        ? <><a*/}
          {/*            href={ `/entries/${ longest_entry.date }` }>{ longest_entry.date }</a> ({ longest_entry.char_count } characters)</>*/}
          {/*        : <span>No entries yet!</span>*/}
          {/*}*/}
        </p>
      </div>
    </Page>
  );
};

export default StatsScreen;
