/* tslint:disable */
/* eslint-disable */
/**
 * My App API
 * My App API description
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { EntryMinimal } from './EntryMinimal';
import {
  EntryMinimalFromJSON,
  EntryMinimalFromJSONTyped,
  EntryMinimalToJSON,
  EntryMinimalToJSONTyped,
} from './EntryMinimal';

/**
 *
 * @export
 * @interface SeriesResponse
 */
export interface SeriesResponse {
  /**
   *
   * @type {number}
   * @memberof SeriesResponse
   */
  id: number;
  /**
   *
   * @type {string}
   * @memberof SeriesResponse
   */
  name: string;
  /**
   *
   * @type {number}
   * @memberof SeriesResponse
   */
  wordCount: number;
  /**
   *
   * @type {number}
   * @memberof SeriesResponse
   */
  charCount: number;
  /**
   *
   * @type {Array<EntryMinimal>}
   * @memberof SeriesResponse
   */
  entries: Array<EntryMinimal>;
}

/**
 * Check if a given object implements the SeriesResponse interface.
 */
export function instanceOfSeriesResponse(value: object): value is SeriesResponse {
  if (!('id' in value) || value['id'] === undefined) return false;
  if (!('name' in value) || value['name'] === undefined) return false;
  if (!('wordCount' in value) || value['wordCount'] === undefined) return false;
  if (!('charCount' in value) || value['charCount'] === undefined) return false;
  if (!('entries' in value) || value['entries'] === undefined) return false;
  return true;
}

export function SeriesResponseFromJSON(json: any): SeriesResponse {
  return SeriesResponseFromJSONTyped(json, false);
}

export function SeriesResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): SeriesResponse {
  if (json == null) {
    return json;
  }
  return {
    id: json['id'],
    name: json['name'],
    wordCount: json['wordCount'],
    charCount: json['charCount'],
    entries: (json['entries'] as Array<any>).map(EntryMinimalFromJSON),
  };
}

export function SeriesResponseToJSON(json: any): SeriesResponse {
  return SeriesResponseToJSONTyped(json, false);
}

export function SeriesResponseToJSONTyped(value?: SeriesResponse | null, ignoreDiscriminator: boolean = false): any {
  if (value == null) {
    return value;
  }

  return {
    id: value['id'],
    name: value['name'],
    wordCount: value['wordCount'],
    charCount: value['charCount'],
    entries: (value['entries'] as Array<any>).map(EntryMinimalToJSON),
  };
}
