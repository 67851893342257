/* tslint:disable */
/* eslint-disable */
/**
 * My App API
 * My App API description
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 *
 * @export
 * @interface PromptResponse
 */
export interface PromptResponse {
  /**
   *
   * @type {string}
   * @memberof PromptResponse
   */
  text?: string;
}

/**
 * Check if a given object implements the PromptResponse interface.
 */
export function instanceOfPromptResponse(value: object): value is PromptResponse {
  return true;
}

export function PromptResponseFromJSON(json: any): PromptResponse {
  return PromptResponseFromJSONTyped(json, false);
}

export function PromptResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): PromptResponse {
  if (json == null) {
    return json;
  }
  return {
    text: json['text'] == null ? undefined : json['text'],
  };
}

export function PromptResponseToJSON(json: any): PromptResponse {
  return PromptResponseToJSONTyped(json, false);
}

export function PromptResponseToJSONTyped(value?: PromptResponse | null, ignoreDiscriminator: boolean = false): any {
  if (value == null) {
    return value;
  }

  return {
    text: value['text'],
  };
}
