import { PencilIcon, SparklesIcon } from '@heroicons/react/24/solid';
import { Button, Text, Tooltip } from '@mantine/core';
import { useForm } from '@mantine/form';
import { useQuery } from '@tanstack/react-query';
import { useEffect } from 'react';
import api from '../../api.ts';
import type { EntryResponseTypeEnum, PromptResponse } from '../../client';
import LoadingScreen from '../LoadingScreen';
import { useEntry } from './EntryContext.tsx';
import SidebarElement from './SidebarElement.tsx';

const CurrentPrompt = () => {
  const query = useQuery<PromptResponse>({
    queryKey: ['api', 'v1', 'prompts'],
    queryFn: () => api.listPrompts(),
  });

  if (query.isLoading) {
    return <LoadingScreen />;
  }

  const prompt = query.data;
  if (!prompt || query.isError) {
    return <Text size="sm">Unable to generate a prompt!</Text>;
  }

  return <Text size="sm">{prompt.text}</Text>;
};

export default function SelectMode() {
  const { entry, setEntryMode } = useEntry();
  const { initialize, ...form } = useForm({
    mode: 'controlled',
    initialValues: {
      type: 'freeform',
    },
    onValuesChange: async (values, prev) => {
      // TODO: This fires a patch on mount, because the form is initialized with a value that is different from the previous value.
      if (values.type !== prev.type) {
        await setEntryMode(values.type as EntryResponseTypeEnum);
      }
    },
  });

  useEffect(() => {
    if (!form.initialized && entry) {
      initialize({
        type: entry.type,
      });
    }
  }, [entry, form.initialized, initialize]);

  return (
    <>
      {/* TODO: Extract this into a ToggleButton group component */}
      <Button.Group>
        <Tooltip label="Write whatever you like, using your own inspiration.">
          <Button
            variant="default"
            disabled={form.values.type === 'freeform'}
            onClick={() => form.setFieldValue('type', 'freeform')}
            leftSection={<PencilIcon width={16} />}
          >
            Freeform
          </Button>
        </Tooltip>
        <Tooltip label="Write using a daily prompt, if you're stuck for ideas!">
          <Button
            variant="default"
            disabled={form.values.type === 'prompt'}
            onClick={() => form.setFieldValue('type', 'prompt')}
            leftSection={<SparklesIcon width={16} />}
          >
            Prompt
          </Button>
        </Tooltip>
      </Button.Group>

      {form.values.type === 'prompt' && (
        <SidebarElement
          title="Prompt"
          tooltip="A daily prompt generated for inspiration. Feel free to use it, or not use it!"
        >
          <CurrentPrompt />
        </SidebarElement>
      )}
    </>
  );
}
