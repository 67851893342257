import { Box, Paper, Stack } from '@mantine/core';
import Editor from './Editor.tsx';
import '@mdxeditor/editor/style.css';
import EntryContent from './EntryContent.tsx';
import EntryTitle from './EntryTitle.tsx';

export function EntryEdit() {
  // TODO: Notify if the date crosses over into tomorrow (or past the user's set "end of day" threshold)
  // TODO: Store content in local storage or something until it's saved

  const editor = (
    <Stack gap={8}>
      <EntryTitle />

      <Box>
        <Paper mih={300} radius="sm" withBorder>
          <EntryContent />
        </Paper>
      </Box>
    </Stack>
  );

  return <Editor>{editor}</Editor>;
}
