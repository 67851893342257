import * as Sentry from '@sentry/react';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { App } from './app';
import './index.css';
import { Amplify } from 'aws-amplify';
import {
  createBrowserRouter,
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom';
import '@aws-amplify/ui-react/styles.css'; // @ts-expect-error: This is an AWS export that has nothing to do with us
import awsExports from './aws-exports';
import '@fontsource/inter/300.css';
import '@fontsource/inter/400.css';
import '@fontsource/inter/500.css';
import '@fontsource/inter/600.css';
import '@fontsource/inter/700.css';
import { Authenticator } from '@aws-amplify/ui-react';
import { MantineProvider, createTheme } from '@mantine/core';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

Sentry.init({
  dsn: 'https://bfb3de9f0eae3d3c09430c71c13ce74a@o103099.ingest.sentry.io/4506623152750592',
  integrations: [
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect: React.useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const sentryCreateBrowserRouter = Sentry.wrapCreateBrowserRouter(createBrowserRouter);

const userPoolId = import.meta.env.PROD ? 'eu-west-1_nwnPH7w0P' : 'eu-west-1_lLITzmo7E';

const userPoolWebClientId = import.meta.env.PROD ? '5i3prsudejhb9edtmeif00t2pb' : '225b99a7imqqd8rs4o8n4c9el9';

const oauthDomain = import.meta.env.PROD
  ? 'writual-prod.auth.eu-west-1.amazoncognito.com'
  : 'writual-dev.auth.eu-west-1.amazoncognito.com';

Amplify.configure({
  ...awsExports,
  aws_user_pools_id: userPoolId,
  aws_user_pools_web_client_id: userPoolWebClientId,
  oauth: {
    domain: oauthDomain,
  },
});

const queryClient = new QueryClient();

const theme = createTheme({
  fontFamily: 'Inter, sans-serif',
});

const app = (
  <React.StrictMode>
    <Sentry.ErrorBoundary fallback={<p>An error has occurred</p>}>
      <MantineProvider theme={theme}>
        <QueryClientProvider client={queryClient}>
          <Authenticator.Provider>
            <App />
          </Authenticator.Provider>
        </QueryClientProvider>
      </MantineProvider>
    </Sentry.ErrorBoundary>
  </React.StrictMode>
);

// @ts-expect-error: This will almost definitely never be null
ReactDOM.createRoot(document.getElementById('app')).render(app);
