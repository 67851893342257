/* tslint:disable */
/* eslint-disable */
/**
 * My App API
 * My App API description
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type {
  EntryCreateRequest,
  EntryListResponse,
  EntryResponse,
  GoalListResponse,
  GoalRequest,
  GoalResponse,
  HttpProblem,
  PromptResponse,
  SeriesListResponse,
  SeriesRequest,
  SeriesResponse,
  SettingsResponse,
  SharedEntryResponse,
  StatsResponse,
  TagListResponse,
  TagRequest,
  TagResponse,
  TokenResponse,
} from '../models/index';
import {
  EntryCreateRequestFromJSON,
  EntryCreateRequestToJSON,
  EntryListResponseFromJSON,
  EntryListResponseToJSON,
  EntryResponseFromJSON,
  EntryResponseToJSON,
  GoalListResponseFromJSON,
  GoalListResponseToJSON,
  GoalRequestFromJSON,
  GoalRequestToJSON,
  GoalResponseFromJSON,
  GoalResponseToJSON,
  HttpProblemFromJSON,
  HttpProblemToJSON,
  PromptResponseFromJSON,
  PromptResponseToJSON,
  SeriesListResponseFromJSON,
  SeriesListResponseToJSON,
  SeriesRequestFromJSON,
  SeriesRequestToJSON,
  SeriesResponseFromJSON,
  SeriesResponseToJSON,
  SettingsResponseFromJSON,
  SettingsResponseToJSON,
  SharedEntryResponseFromJSON,
  SharedEntryResponseToJSON,
  StatsResponseFromJSON,
  StatsResponseToJSON,
  TagListResponseFromJSON,
  TagListResponseToJSON,
  TagRequestFromJSON,
  TagRequestToJSON,
  TagResponseFromJSON,
  TagResponseToJSON,
  TokenResponseFromJSON,
  TokenResponseToJSON,
} from '../models/index';
import * as runtime from '../runtime';

export interface CreateEntryRequest {
  entryCreateRequest?: EntryCreateRequest;
}

export interface CreateGoalRequest {
  goalRequest?: GoalRequest;
}

export interface CreateSeriesRequest {
  seriesRequest?: SeriesRequest;
}

export interface CreateTagRequest {
  tagRequest?: TagRequest;
}

export interface DeleteEntryRequest {
  id: number;
}

export interface DeleteSeriesRequest {
  id: number;
}

export interface DeleteTagRequest {
  id: number;
}

export interface GetEntryRequest {
  id: number;
}

export interface GetEntryPdfRequest {
  id: number;
}

export interface GetGoalRequest {
  id: number;
}

export interface GetSeriesRequest {
  id: number;
}

export interface GetSharedEntryRequest {
  sid: string;
}

export interface GetTagRequest {
  id: number;
}

export interface PatchEntryRequest {
  id: number;
  body?: object;
}

export interface PatchGoalRequest {
  id: number;
  body?: object;
}

export interface PatchSeriesRequest {
  id: number;
  body?: object;
}

export interface PatchSettingsRequest {
  body?: object;
}

export interface PatchTagRequest {
  id: number;
  body?: object;
}

/**
 *
 */
export class DefaultApi extends runtime.BaseAPI {
  /**
   */
  async createEntryRaw(
    requestParameters: CreateEntryRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<EntryResponse>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request(
      {
        path: `/api/v1/entries`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: EntryCreateRequestToJSON(requestParameters['entryCreateRequest']),
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => EntryResponseFromJSON(jsonValue));
  }

  /**
   */
  async createEntry(
    requestParameters: CreateEntryRequest = {},
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<EntryResponse> {
    const response = await this.createEntryRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   */
  async createGoalRaw(
    requestParameters: CreateGoalRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<GoalResponse>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request(
      {
        path: `/api/v1/goals`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: GoalRequestToJSON(requestParameters['goalRequest']),
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => GoalResponseFromJSON(jsonValue));
  }

  /**
   */
  async createGoal(
    requestParameters: CreateGoalRequest = {},
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<GoalResponse> {
    const response = await this.createGoalRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   */
  async createSeriesRaw(
    requestParameters: CreateSeriesRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<SeriesResponse>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request(
      {
        path: `/api/v1/series`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: SeriesRequestToJSON(requestParameters['seriesRequest']),
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => SeriesResponseFromJSON(jsonValue));
  }

  /**
   */
  async createSeries(
    requestParameters: CreateSeriesRequest = {},
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<SeriesResponse> {
    const response = await this.createSeriesRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   */
  async createTagRaw(
    requestParameters: CreateTagRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<TagResponse>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request(
      {
        path: `/api/v1/tags`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: TagRequestToJSON(requestParameters['tagRequest']),
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => TagResponseFromJSON(jsonValue));
  }

  /**
   */
  async createTag(
    requestParameters: CreateTagRequest = {},
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<TagResponse> {
    const response = await this.createTagRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   */
  async createTokenRaw(
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<TokenResponse>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/api/v1/tokens`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => TokenResponseFromJSON(jsonValue));
  }

  /**
   */
  async createToken(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<TokenResponse> {
    const response = await this.createTokenRaw(initOverrides);
    return await response.value();
  }

  /**
   */
  async deleteEntryRaw(
    requestParameters: DeleteEntryRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<void>> {
    if (requestParameters['id'] == null) {
      throw new runtime.RequiredError(
        'id',
        'Required parameter "id" was null or undefined when calling deleteEntry().',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/api/v1/entries/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(requestParameters['id']))),
        method: 'DELETE',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   */
  async deleteEntry(
    requestParameters: DeleteEntryRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<void> {
    await this.deleteEntryRaw(requestParameters, initOverrides);
  }

  /**
   */
  async deleteSeriesRaw(
    requestParameters: DeleteSeriesRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<void>> {
    if (requestParameters['id'] == null) {
      throw new runtime.RequiredError(
        'id',
        'Required parameter "id" was null or undefined when calling deleteSeries().',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/api/v1/series/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(requestParameters['id']))),
        method: 'DELETE',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   */
  async deleteSeries(
    requestParameters: DeleteSeriesRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<void> {
    await this.deleteSeriesRaw(requestParameters, initOverrides);
  }

  /**
   */
  async deleteTagRaw(
    requestParameters: DeleteTagRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<void>> {
    if (requestParameters['id'] == null) {
      throw new runtime.RequiredError('id', 'Required parameter "id" was null or undefined when calling deleteTag().');
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/api/v1/tags/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(requestParameters['id']))),
        method: 'DELETE',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   */
  async deleteTag(
    requestParameters: DeleteTagRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<void> {
    await this.deleteTagRaw(requestParameters, initOverrides);
  }

  /**
   */
  async getEntryRaw(
    requestParameters: GetEntryRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<EntryResponse>> {
    if (requestParameters['id'] == null) {
      throw new runtime.RequiredError('id', 'Required parameter "id" was null or undefined when calling getEntry().');
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/api/v1/entries/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(requestParameters['id']))),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => EntryResponseFromJSON(jsonValue));
  }

  /**
   */
  async getEntry(
    requestParameters: GetEntryRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<EntryResponse> {
    const response = await this.getEntryRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   */
  async getEntryPdfRaw(
    requestParameters: GetEntryPdfRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<void>> {
    if (requestParameters['id'] == null) {
      throw new runtime.RequiredError(
        'id',
        'Required parameter "id" was null or undefined when calling getEntryPdf().',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/api/v1/entries/{id}/pdf`.replace(`{${'id'}}`, encodeURIComponent(String(requestParameters['id']))),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   */
  async getEntryPdf(
    requestParameters: GetEntryPdfRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<void> {
    await this.getEntryPdfRaw(requestParameters, initOverrides);
  }

  /**
   */
  async getGoalRaw(
    requestParameters: GetGoalRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<GoalResponse>> {
    if (requestParameters['id'] == null) {
      throw new runtime.RequiredError('id', 'Required parameter "id" was null or undefined when calling getGoal().');
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/api/v1/goals/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(requestParameters['id']))),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => GoalResponseFromJSON(jsonValue));
  }

  /**
   */
  async getGoal(
    requestParameters: GetGoalRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<GoalResponse> {
    const response = await this.getGoalRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   */
  async getSeriesRaw(
    requestParameters: GetSeriesRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<SeriesResponse>> {
    if (requestParameters['id'] == null) {
      throw new runtime.RequiredError('id', 'Required parameter "id" was null or undefined when calling getSeries().');
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/api/v1/series/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(requestParameters['id']))),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => SeriesResponseFromJSON(jsonValue));
  }

  /**
   */
  async getSeries(
    requestParameters: GetSeriesRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<SeriesResponse> {
    const response = await this.getSeriesRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   */
  async getSettingsRaw(
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<SettingsResponse>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/api/v1/settings`,
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => SettingsResponseFromJSON(jsonValue));
  }

  /**
   */
  async getSettings(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SettingsResponse> {
    const response = await this.getSettingsRaw(initOverrides);
    return await response.value();
  }

  /**
   */
  async getSharedEntryRaw(
    requestParameters: GetSharedEntryRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<SharedEntryResponse>> {
    if (requestParameters['sid'] == null) {
      throw new runtime.RequiredError(
        'sid',
        'Required parameter "sid" was null or undefined when calling getSharedEntry().',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/api/v1/shared/entries/{sid}`.replace(
          `{${'sid'}}`,
          encodeURIComponent(String(requestParameters['sid'])),
        ),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => SharedEntryResponseFromJSON(jsonValue));
  }

  /**
   */
  async getSharedEntry(
    requestParameters: GetSharedEntryRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<SharedEntryResponse> {
    const response = await this.getSharedEntryRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   */
  async getStatsRaw(
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<StatsResponse>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/api/v1/stats`,
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => StatsResponseFromJSON(jsonValue));
  }

  /**
   */
  async getStats(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<StatsResponse> {
    const response = await this.getStatsRaw(initOverrides);
    return await response.value();
  }

  /**
   */
  async getTagRaw(
    requestParameters: GetTagRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<TagResponse>> {
    if (requestParameters['id'] == null) {
      throw new runtime.RequiredError('id', 'Required parameter "id" was null or undefined when calling getTag().');
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/api/v1/tags/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(requestParameters['id']))),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => TagResponseFromJSON(jsonValue));
  }

  /**
   */
  async getTag(
    requestParameters: GetTagRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<TagResponse> {
    const response = await this.getTagRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   */
  async listEntriesRaw(
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<EntryListResponse>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/api/v1/entries`,
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => EntryListResponseFromJSON(jsonValue));
  }

  /**
   */
  async listEntries(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<EntryListResponse> {
    const response = await this.listEntriesRaw(initOverrides);
    return await response.value();
  }

  /**
   */
  async listGoalsRaw(
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<GoalListResponse>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/api/v1/goals`,
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => GoalListResponseFromJSON(jsonValue));
  }

  /**
   */
  async listGoals(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GoalListResponse> {
    const response = await this.listGoalsRaw(initOverrides);
    return await response.value();
  }

  /**
   */
  async listPromptsRaw(
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<PromptResponse>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/api/v1/prompts`,
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => PromptResponseFromJSON(jsonValue));
  }

  /**
   */
  async listPrompts(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PromptResponse> {
    const response = await this.listPromptsRaw(initOverrides);
    return await response.value();
  }

  /**
   */
  async listSeriesRaw(
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<SeriesListResponse>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/api/v1/series`,
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => SeriesListResponseFromJSON(jsonValue));
  }

  /**
   */
  async listSeries(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SeriesListResponse> {
    const response = await this.listSeriesRaw(initOverrides);
    return await response.value();
  }

  /**
   */
  async listTagsRaw(
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<TagListResponse>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/api/v1/tags`,
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => TagListResponseFromJSON(jsonValue));
  }

  /**
   */
  async listTags(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<TagListResponse> {
    const response = await this.listTagsRaw(initOverrides);
    return await response.value();
  }

  /**
   */
  async patchEntryRaw(
    requestParameters: PatchEntryRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<EntryResponse>> {
    if (requestParameters['id'] == null) {
      throw new runtime.RequiredError('id', 'Required parameter "id" was null or undefined when calling patchEntry().');
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request(
      {
        path: `/api/v1/entries/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(requestParameters['id']))),
        method: 'PATCH',
        headers: headerParameters,
        query: queryParameters,
        body: requestParameters['body'] as any,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => EntryResponseFromJSON(jsonValue));
  }

  /**
   */
  async patchEntry(
    requestParameters: PatchEntryRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<EntryResponse> {
    const response = await this.patchEntryRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   */
  async patchGoalRaw(
    requestParameters: PatchGoalRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<GoalResponse>> {
    if (requestParameters['id'] == null) {
      throw new runtime.RequiredError('id', 'Required parameter "id" was null or undefined when calling patchGoal().');
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request(
      {
        path: `/api/v1/goals/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(requestParameters['id']))),
        method: 'PATCH',
        headers: headerParameters,
        query: queryParameters,
        body: requestParameters['body'] as any,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => GoalResponseFromJSON(jsonValue));
  }

  /**
   */
  async patchGoal(
    requestParameters: PatchGoalRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<GoalResponse> {
    const response = await this.patchGoalRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   */
  async patchSeriesRaw(
    requestParameters: PatchSeriesRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<SeriesResponse>> {
    if (requestParameters['id'] == null) {
      throw new runtime.RequiredError(
        'id',
        'Required parameter "id" was null or undefined when calling patchSeries().',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request(
      {
        path: `/api/v1/series/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(requestParameters['id']))),
        method: 'PATCH',
        headers: headerParameters,
        query: queryParameters,
        body: requestParameters['body'] as any,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => SeriesResponseFromJSON(jsonValue));
  }

  /**
   */
  async patchSeries(
    requestParameters: PatchSeriesRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<SeriesResponse> {
    const response = await this.patchSeriesRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   */
  async patchSettingsRaw(
    requestParameters: PatchSettingsRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<SettingsResponse>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request(
      {
        path: `/api/v1/settings`,
        method: 'PATCH',
        headers: headerParameters,
        query: queryParameters,
        body: requestParameters['body'] as any,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => SettingsResponseFromJSON(jsonValue));
  }

  /**
   */
  async patchSettings(
    requestParameters: PatchSettingsRequest = {},
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<SettingsResponse> {
    const response = await this.patchSettingsRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   */
  async patchTagRaw(
    requestParameters: PatchTagRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<TagResponse>> {
    if (requestParameters['id'] == null) {
      throw new runtime.RequiredError('id', 'Required parameter "id" was null or undefined when calling patchTag().');
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request(
      {
        path: `/api/v1/tags/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(requestParameters['id']))),
        method: 'PATCH',
        headers: headerParameters,
        query: queryParameters,
        body: requestParameters['body'] as any,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => TagResponseFromJSON(jsonValue));
  }

  /**
   */
  async patchTag(
    requestParameters: PatchTagRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<TagResponse> {
    const response = await this.patchTagRaw(requestParameters, initOverrides);
    return await response.value();
  }
}
