import { Authenticator } from '@aws-amplify/ui-react';
import { Box } from '@mantine/core';

export default function LoginScreen() {
  return (
    <Box pt="xl">
      <Authenticator />
    </Box>
  );
}
