/* tslint:disable */
/* eslint-disable */
/**
 * My App API
 * My App API description
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 *
 * @export
 * @interface Series
 */
export interface Series {
  /**
   *
   * @type {number}
   * @memberof Series
   */
  id?: number;
}

/**
 * Check if a given object implements the Series interface.
 */
export function instanceOfSeries(value: object): value is Series {
  return true;
}

export function SeriesFromJSON(json: any): Series {
  return SeriesFromJSONTyped(json, false);
}

export function SeriesFromJSONTyped(json: any, ignoreDiscriminator: boolean): Series {
  if (json == null) {
    return json;
  }
  return {
    id: json['id'] == null ? undefined : json['id'],
  };
}

export function SeriesToJSON(json: any): Series {
  return SeriesToJSONTyped(json, false);
}

export function SeriesToJSONTyped(value?: Series | null, ignoreDiscriminator: boolean = false): any {
  if (value == null) {
    return value;
  }

  return {
    id: value['id'],
  };
}
