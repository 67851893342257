/* tslint:disable */
/* eslint-disable */
/**
 * My App API
 * My App API description
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 *
 * @export
 * @interface SharedEntryResponse
 */
export interface SharedEntryResponse {
  /**
   *
   * @type {string}
   * @memberof SharedEntryResponse
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof SharedEntryResponse
   */
  title: string;
  /**
   *
   * @type {string}
   * @memberof SharedEntryResponse
   */
  author: string;
  /**
   *
   * @type {string}
   * @memberof SharedEntryResponse
   */
  content: string;
  /**
   *
   * @type {Date}
   * @memberof SharedEntryResponse
   */
  date: Date;
}

/**
 * Check if a given object implements the SharedEntryResponse interface.
 */
export function instanceOfSharedEntryResponse(value: object): value is SharedEntryResponse {
  if (!('id' in value) || value['id'] === undefined) return false;
  if (!('title' in value) || value['title'] === undefined) return false;
  if (!('author' in value) || value['author'] === undefined) return false;
  if (!('content' in value) || value['content'] === undefined) return false;
  if (!('date' in value) || value['date'] === undefined) return false;
  return true;
}

export function SharedEntryResponseFromJSON(json: any): SharedEntryResponse {
  return SharedEntryResponseFromJSONTyped(json, false);
}

export function SharedEntryResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): SharedEntryResponse {
  if (json == null) {
    return json;
  }
  return {
    id: json['id'],
    title: json['title'],
    author: json['author'],
    content: json['content'],
    date: new Date(json['date']),
  };
}

export function SharedEntryResponseToJSON(json: any): SharedEntryResponse {
  return SharedEntryResponseToJSONTyped(json, false);
}

export function SharedEntryResponseToJSONTyped(
  value?: SharedEntryResponse | null,
  ignoreDiscriminator: boolean = false,
): any {
  if (value == null) {
    return value;
  }

  return {
    id: value['id'],
    title: value['title'],
    author: value['author'],
    content: value['content'],
    date: value['date'].toISOString().substring(0, 10),
  };
}
