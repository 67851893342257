import { PlusIcon } from '@heroicons/react/16/solid';
import { Button, Grid, Group, Modal, Select, TextInput } from '@mantine/core';
import { useForm } from '@mantine/form';
import { useDisclosure } from '@mantine/hooks';
import { notifications } from '@mantine/notifications';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useEffect } from 'react';
import api from '../../api.ts';
import type { SeriesListResponse } from '../../client';
import FormSubmit from '../FormSubmit.tsx';
import { useEntry } from './EntryContext.tsx';

interface AddSerialRequest {
  name: string;
}

interface AddSerialModalProps {
  onSave: (id: number) => void;
}

export function AddSerialModal({ onSave }: AddSerialModalProps) {
  const queryClient = useQueryClient();
  const [opened, { open, close }] = useDisclosure(false);

  const initialValues: AddSerialRequest = {
    name: '',
  };

  const form = useForm({
    mode: 'uncontrolled',
    initialValues,
    validate: {
      name: (value) => (!value ? 'Required' : null),
    },
  });

  const createSerial = useMutation({
    mutationFn: (values: typeof form.values) => {
      return api.createSeries({
        seriesRequest: {
          name: values.name,
        },
      });
    },
    onError: (error) => {
      console.error(error);
      notifications.show({
        title: 'Error',
        message: 'Failed to add serial. Please try again!',
        color: 'red',
      });
    },
    onSuccess: async (serial) => {
      form.reset();
      await queryClient.invalidateQueries({
        queryKey: ['api', 'v1', 'series'],
      });
      onSave(serial.id);
      close();
    },
  });

  return (
    <>
      <Button variant="subtle" leftSection={<PlusIcon width={16} />} onClick={open}>
        Add
      </Button>

      <Modal centered opened={opened} onClose={close} title="Add new serial">
        <form onSubmit={form.onSubmit((e) => createSerial.mutate(e))}>
          <Group grow preventGrowOverflow={false} justify="space-between">
            <TextInput data-autofocus key={form.key('name')} {...form.getInputProps('name')} />
            <FormSubmit loading={createSerial.isPending}>Save</FormSubmit>
          </Group>
        </form>
      </Modal>
    </>
  );
}

export default function SelectSerial() {
  const { entry, setEntrySerial } = useEntry();

  const { initialize, ...form } = useForm({
    mode: 'uncontrolled',
    enhanceGetInputProps: (payload) => {
      if (!payload.form.initialized) {
        return { disabled: true };
      }

      return {};
    },
    onValuesChange: async (values, prev) => {
      if (values.serial !== prev.serial) {
        await setEntrySerial(values.serial ? Number.parseInt(values.serial) : null);
      }
    },
  });

  useEffect(() => {
    if (!form.initialized && entry) {
      initialize({
        serial: entry?.series?.id ? entry?.series?.id.toString() : null,
      });
    }
  }, [entry, form.initialized, initialize]);

  const query = useQuery<SeriesListResponse>({
    queryKey: ['api', 'v1', 'series'],
    queryFn: () => api.listSeries(),
  });

  if (query.isError) {
    // TODO
    return <div>Error!</div>;
  }

  if (query.isLoading) {
    // TODO
    return <div>Loading...</div>;
  }

  const series = query.data;
  if (!series) {
    // TODO
    return <div>Something went wrong</div>;
  }

  const options = series?.items.map((t) => ({
    value: t.id.toString(),
    label: t.name,
  }));

  return (
    <Grid>
      <Grid.Col span={8}>
        <Select
          key={form.key('serial')}
          {...form.getInputProps('serial')}
          clearable
          data={options}
          placeholder="Pick a serial"
        />
      </Grid.Col>

      <Grid.Col span="auto">
        <AddSerialModal onSave={(id) => form.setFieldValue('serial', id.toString())} />
      </Grid.Col>
    </Grid>
  );
}
