/* tslint:disable */
/* eslint-disable */
/**
 * My App API
 * My App API description
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 *
 * @export
 * @interface StatsResponse
 */
export interface StatsResponse {
  /**
   *
   * @type {number}
   * @memberof StatsResponse
   */
  totalEntries?: number;
  /**
   *
   * @type {number}
   * @memberof StatsResponse
   */
  totalWords?: number;
  /**
   *
   * @type {number}
   * @memberof StatsResponse
   */
  shortestEntry?: number;
  /**
   *
   * @type {number}
   * @memberof StatsResponse
   */
  longestEntry?: number;
  /**
   *
   * @type {number}
   * @memberof StatsResponse
   */
  currentStreak?: number;
  /**
   *
   * @type {number}
   * @memberof StatsResponse
   */
  longestStreak?: number;
}

/**
 * Check if a given object implements the StatsResponse interface.
 */
export function instanceOfStatsResponse(value: object): value is StatsResponse {
  return true;
}

export function StatsResponseFromJSON(json: any): StatsResponse {
  return StatsResponseFromJSONTyped(json, false);
}

export function StatsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): StatsResponse {
  if (json == null) {
    return json;
  }
  return {
    totalEntries: json['totalEntries'] == null ? undefined : json['totalEntries'],
    totalWords: json['totalWords'] == null ? undefined : json['totalWords'],
    shortestEntry: json['shortestEntry'] == null ? undefined : json['shortestEntry'],
    longestEntry: json['longestEntry'] == null ? undefined : json['longestEntry'],
    currentStreak: json['currentStreak'] == null ? undefined : json['currentStreak'],
    longestStreak: json['longestStreak'] == null ? undefined : json['longestStreak'],
  };
}

export function StatsResponseToJSON(json: any): StatsResponse {
  return StatsResponseToJSONTyped(json, false);
}

export function StatsResponseToJSONTyped(value?: StatsResponse | null, ignoreDiscriminator: boolean = false): any {
  if (value == null) {
    return value;
  }

  return {
    totalEntries: value['totalEntries'],
    totalWords: value['totalWords'],
    shortestEntry: value['shortestEntry'],
    longestEntry: value['longestEntry'],
    currentStreak: value['currentStreak'],
    longestStreak: value['longestStreak'],
  };
}
