import {notifications} from '@mantine/notifications';
import {useMutation, useQueryClient} from '@tanstack/react-query';
import api from '../../api.ts';
import type {EntryResponse} from '../../client';
import {useEntry} from './EntryContext.tsx';

function checkOnline() {
  if (!navigator.onLine) {
    notifications.show({
      autoClose: false,
      id: 'offline',
      title: 'Offline',
      message: 'You are currently offline. Your changes will be saved when you come back online.',
      color: 'yellow',
    });
    return false;
  }

  notifications.hide('offline');
  return true;
}

interface Values {
  id: number;
  patch: { op: string; path: string; value?: unknown }[];
}

export default function usePatchEntry(onSuccess?: (entry: EntryResponse) => Promise<void>) {
  const queryClient = useQueryClient();
  const { setEntry } = useEntry();

  return useMutation({
    mutationFn: async (values: Values) => {
      if (!checkOnline()) {
        return null;
      }

      return api.patchEntry({
        id: values.id,
        body: values.patch,
      });
    },
    onError: (error) => {
      console.error(error);
      notifications.show({
        autoClose: false,
        title: 'Error',
        message:
          'Failed to save the entry. Please copy your content, and refresh the page to try saving the entry again!',
        color: 'red',
      });
    },
    onSuccess: async (response) => {
      if (response === null) {
        return;
      }

      setEntry(response);
      await queryClient.setQueryData(['api', 'v1', 'entries', response.id], response);

      if (onSuccess) {
        await onSuccess(response);
      }
    },
  });
}
