/* tslint:disable */
/* eslint-disable */
/**
 * My App API
 * My App API description
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { EntryListResponseItem } from './EntryListResponseItem';
import {
  EntryListResponseItemFromJSON,
  EntryListResponseItemFromJSONTyped,
  EntryListResponseItemToJSON,
  EntryListResponseItemToJSONTyped,
} from './EntryListResponseItem';
import type { EntryListResponseMeta } from './EntryListResponseMeta';
import {
  EntryListResponseMetaFromJSON,
  EntryListResponseMetaFromJSONTyped,
  EntryListResponseMetaToJSON,
  EntryListResponseMetaToJSONTyped,
} from './EntryListResponseMeta';

/**
 *
 * @export
 * @interface EntryListResponse
 */
export interface EntryListResponse {
  /**
   *
   * @type {Array<EntryListResponseItem>}
   * @memberof EntryListResponse
   */
  items: Array<EntryListResponseItem>;
  /**
   *
   * @type {EntryListResponseMeta}
   * @memberof EntryListResponse
   */
  meta: EntryListResponseMeta;
}

/**
 * Check if a given object implements the EntryListResponse interface.
 */
export function instanceOfEntryListResponse(value: object): value is EntryListResponse {
  if (!('items' in value) || value['items'] === undefined) return false;
  if (!('meta' in value) || value['meta'] === undefined) return false;
  return true;
}

export function EntryListResponseFromJSON(json: any): EntryListResponse {
  return EntryListResponseFromJSONTyped(json, false);
}

export function EntryListResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): EntryListResponse {
  if (json == null) {
    return json;
  }
  return {
    items: (json['items'] as Array<any>).map(EntryListResponseItemFromJSON),
    meta: EntryListResponseMetaFromJSON(json['meta']),
  };
}

export function EntryListResponseToJSON(json: any): EntryListResponse {
  return EntryListResponseToJSONTyped(json, false);
}

export function EntryListResponseToJSONTyped(
  value?: EntryListResponse | null,
  ignoreDiscriminator: boolean = false,
): any {
  if (value == null) {
    return value;
  }

  return {
    items: (value['items'] as Array<any>).map(EntryListResponseItemToJSON),
    meta: EntryListResponseMetaToJSON(value['meta']),
  };
}
