import { useQuery } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';
import api from '../../api.ts';
import type { EntryResponse } from '../../client';
import { EntryProvider } from '../EntryScreen/EntryContext.tsx';
import { EntryEdit } from '../EntryScreen/EntryEdit.tsx';
import ErrorScreen from '../ErrorScreen.tsx';
import LoadingScreen from '../LoadingScreen';
import Page from '../Page';
import PageTitle from '../PageTitle';

type EntryEditParams = {
  id: string;
};

export default function EntryEditScreen() {
  const { id } = useParams<EntryEditParams>() as EntryEditParams;
  const query = useQuery<EntryResponse | undefined>({
    queryKey: ['api', 'v1', 'entries', Number.parseInt(id)],
    queryFn: () =>
      api.getEntry({
        id: Number.parseInt(id),
      }),
    refetchOnWindowFocus: false,
    refetchOnMount: 'always',
    refetchInterval: false,
    refetchOnReconnect: false,
  });

  if (query.isLoading) {
    return <LoadingScreen />;
  }

  if (query.isError) {
    return <ErrorScreen error={query.error} />;
  }

  const entry = query.data;
  if (!entry) {
    // TODO
    return <div>Entry not found</div>;
  }

  return (
    <Page maxWidth="xl" title={<PageTitle title="Edit Entry" />}>
      <EntryProvider id={entry.id}>
        <EntryEdit />
      </EntryProvider>
    </Page>
  );
}
