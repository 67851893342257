import { QueryObserver, useQueryClient } from '@tanstack/react-query';
import type React from 'react';
import { createContext, useCallback, useContext, useEffect, useRef, useState } from 'react';
import { type EntryResponse, EntryResponseTypeEnum } from '../../client';
import usePatchEntry from './usePatchEntry.ts';
import useUpsertEntry from './useUpsertEntry.ts';

// Title should be editable at any time. If it's filled in before creation, it should be sent in the creation object. It should also be editable still afterwards, with every change debounced as a patch to the API
// Prompt should be editable at any time. If it's chosen before creation, it should be sent in the creation object.
// Series and tags should only be editable if there's an entry in the context (i.e. saved)
// Content for existing entries should always appear in the editor. If it's changed, it should be debounced as a patch to the API
// Continuously typing (and not continously typing) content into a new entry must only create one entry, and patch after that. It must also not bounce the cursor back to the start of the line on first save/creation.

interface EntryContextProps {
  entry: EntryResponse | null;
  entryId: number | null;
  entryIdRef: React.MutableRefObject<number | null>;
  entryLoading: boolean;
  entrySaving: boolean;
  setEntry: (entry: EntryResponse | null) => void;
  setEntryContent: (content: string, wordAdded: number) => Promise<void>;
  setEntryId: (id: number | null) => void;
  setEntryMode: (mode: EntryResponseTypeEnum) => Promise<void>;
  setEntrySerial: (serial: number | null) => Promise<void>;
  setEntryTags: (tags: number[]) => Promise<void>;
  setEntryTitle: (title: string | null) => Promise<void>;
}

const EntryContext = createContext<EntryContextProps>({
  entry: null,
  entryId: null,
  entryIdRef: { current: null },
  entryLoading: false,
  entrySaving: false,
  setEntry: () => {},
  setEntryContent: async () => {},
  setEntryId: () => {},
  setEntryMode: async () => {},
  setEntrySerial: async () => {},
  setEntryTags: async () => {},
  setEntryTitle: async () => {},
});

export const useEntry = () => {
  return useContext(EntryContext);
};

interface EntryProviderProps {
  children: React.ReactNode;
  id: number | null;
}

export function EntryProvider(props: EntryProviderProps) {
  const contentRef = useRef<string>('');
  const entryIdRef = useRef<number | null>(props.id);
  const [entryId, setId] = useState<number | null>(props.id);
  const [entry, setEntry] = useState<EntryResponse | null>(null);
  const [loading, setLoading] = useState<boolean>(props.id !== null);
  const modeRef = useRef<EntryResponseTypeEnum>(EntryResponseTypeEnum.Freeform);
  const serialRef = useRef<number | null>(null);
  const tagsRef = useRef<number[]>([]);
  const titleRef = useRef<string | null>(null);
  const patchEntry = usePatchEntry();

  const setEntryId = (id: number | null) => {
    entryIdRef.current = id;
    setId(id);
  };

  const upsertEntry = useUpsertEntry(setEntry, setEntryId);

  const setEntryContent = useCallback(
    async (content: string, wordsAdded: number) => {
      contentRef.current = content;

      console.log('before saving content', {
        content: content,
        mode: modeRef.current,
        title: titleRef.current,
      });

      await upsertEntry.mutateAsync({
        createValues: {
          content: content,
          title: titleRef.current,
          type: modeRef.current,
        },
        id: entryIdRef.current,
        patchValues: {
          patch: [
            {
              op: 'replace',
              path: '/content',
              value: content,
            },
            {
              op: 'add',
              path: '/wordsAdded',
              value: wordsAdded,
            },
          ],
        },
      });
    },
    [upsertEntry],
  );

  const setEntryMode = async (mode: EntryResponseTypeEnum) => {
    modeRef.current = mode;
    if (entryId) {
      await patchEntry.mutateAsync({
        id: entryId,
        patch: [
          {
            op: 'replace',
            path: '/type',
            value: mode,
          },
        ],
      });
    }
  };

  const setEntrySerial = async (serial: number | null) => {
    serialRef.current = serial;
    if (entryId) {
      await patchEntry.mutateAsync({
        id: entryId,
        patch: [serial ? { op: 'replace', path: '/series', value: { id: serial } } : { op: 'remove', path: '/series' }],
      });
    }
  };

  const setEntryTags = async (tags: number[]) => {
    tagsRef.current = tags;
    if (entryId) {
      await patchEntry.mutateAsync({
        id: entryId,
        patch: [
          {
            op: 'replace',
            path: '/tags',
            value: tags.map((tag) => ({ id: tag })),
          },
        ],
      });
    }
  };

  const setEntryTitle = async (title: string | null) => {
    titleRef.current = title;
    if (entryId) {
      await patchEntry.mutateAsync({
        id: entryId,
        patch: [
          {
            op: 'replace',
            path: '/title',
            value: title,
          },
        ],
      });
    }
  };

  const queryClient = useQueryClient();
  useEffect(() => {
    const observer = new QueryObserver<EntryResponse>(queryClient, { queryKey: ['api', 'v1', 'entries', entryId] });
    const unsubscribe = observer.subscribe((result) => {
      if (result.data) {
        setEntry(result.data);
        setLoading(false);
      }
    });

    return () => {
      unsubscribe();
      observer.destroy();
    };
  }, [entryId, queryClient]);

  return (
    <EntryContext.Provider
      value={{
        entry,
        entryId,
        entryIdRef,
        entryLoading: loading,
        entrySaving: upsertEntry.isPending,
        setEntry,
        setEntryContent,
        setEntryId,
        setEntryMode,
        setEntrySerial,
        setEntryTags,
        setEntryTitle,
      }}
    >
      {props.children}
    </EntryContext.Provider>
  );
}
