/* tslint:disable */
/* eslint-disable */
/**
 * My App API
 * My App API description
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 *
 * @export
 * @interface GoalRequest
 */
export interface GoalRequest {
  /**
   *
   * @type {string}
   * @memberof GoalRequest
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof GoalRequest
   */
  type?: string;
  /**
   *
   * @type {number}
   * @memberof GoalRequest
   */
  target?: number;
  /**
   *
   * @type {Date}
   * @memberof GoalRequest
   */
  targetDate?: Date;
  /**
   *
   * @type {string}
   * @memberof GoalRequest
   */
  recurrence?: string;
  /**
   *
   * @type {boolean}
   * @memberof GoalRequest
   */
  completed?: boolean;
}

/**
 * Check if a given object implements the GoalRequest interface.
 */
export function instanceOfGoalRequest(value: object): value is GoalRequest {
  return true;
}

export function GoalRequestFromJSON(json: any): GoalRequest {
  return GoalRequestFromJSONTyped(json, false);
}

export function GoalRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): GoalRequest {
  if (json == null) {
    return json;
  }
  return {
    name: json['name'] == null ? undefined : json['name'],
    type: json['type'] == null ? undefined : json['type'],
    target: json['target'] == null ? undefined : json['target'],
    targetDate: json['targetDate'] == null ? undefined : new Date(json['targetDate']),
    recurrence: json['recurrence'] == null ? undefined : json['recurrence'],
    completed: json['completed'] == null ? undefined : json['completed'],
  };
}

export function GoalRequestToJSON(json: any): GoalRequest {
  return GoalRequestToJSONTyped(json, false);
}

export function GoalRequestToJSONTyped(value?: GoalRequest | null, ignoreDiscriminator: boolean = false): any {
  if (value == null) {
    return value;
  }

  return {
    name: value['name'],
    type: value['type'],
    target: value['target'],
    targetDate: value['targetDate'] == null ? undefined : value['targetDate'].toISOString().substring(0, 10),
    recurrence: value['recurrence'],
    completed: value['completed'],
  };
}
