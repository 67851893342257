import { fetchAuthSession } from 'aws-amplify/auth';
import { Configuration, DefaultApi, type FetchParams, type RequestContext } from './client';

const configuration = new Configuration({
  basePath: '',
  headers: {
    'Content-Type': 'application/json',
  },
  middleware: [
    {
      async pre(context: RequestContext): Promise<FetchParams> {
        const { accessToken } = (await fetchAuthSession()).tokens ?? {};

        if (accessToken) {
          context.init.headers = {
            ...context.init.headers,
            Authorization: `Bearer ${accessToken}`,
          };
        }

        return Promise.resolve(context);
      },
    },
  ],
});

export default new DefaultApi(configuration);
