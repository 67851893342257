/* tslint:disable */
/* eslint-disable */
/**
 * My App API
 * My App API description
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { EntryMinimal } from './EntryMinimal';
import {
  EntryMinimalFromJSON,
  EntryMinimalFromJSONTyped,
  EntryMinimalToJSON,
  EntryMinimalToJSONTyped,
} from './EntryMinimal';

/**
 *
 * @export
 * @interface TagResponse
 */
export interface TagResponse {
  /**
   *
   * @type {number}
   * @memberof TagResponse
   */
  id: number;
  /**
   *
   * @type {string}
   * @memberof TagResponse
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof TagResponse
   */
  colourBackground: string;
  /**
   *
   * @type {string}
   * @memberof TagResponse
   */
  colourForeground: string;
  /**
   *
   * @type {number}
   * @memberof TagResponse
   */
  wordCount: number;
  /**
   *
   * @type {number}
   * @memberof TagResponse
   */
  charCount: number;
  /**
   *
   * @type {Array<EntryMinimal>}
   * @memberof TagResponse
   */
  entries: Array<EntryMinimal>;
}

/**
 * Check if a given object implements the TagResponse interface.
 */
export function instanceOfTagResponse(value: object): value is TagResponse {
  if (!('id' in value) || value['id'] === undefined) return false;
  if (!('name' in value) || value['name'] === undefined) return false;
  if (!('colourBackground' in value) || value['colourBackground'] === undefined) return false;
  if (!('colourForeground' in value) || value['colourForeground'] === undefined) return false;
  if (!('wordCount' in value) || value['wordCount'] === undefined) return false;
  if (!('charCount' in value) || value['charCount'] === undefined) return false;
  if (!('entries' in value) || value['entries'] === undefined) return false;
  return true;
}

export function TagResponseFromJSON(json: any): TagResponse {
  return TagResponseFromJSONTyped(json, false);
}

export function TagResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): TagResponse {
  if (json == null) {
    return json;
  }
  return {
    id: json['id'],
    name: json['name'],
    colourBackground: json['colourBackground'],
    colourForeground: json['colourForeground'],
    wordCount: json['wordCount'],
    charCount: json['charCount'],
    entries: (json['entries'] as Array<any>).map(EntryMinimalFromJSON),
  };
}

export function TagResponseToJSON(json: any): TagResponse {
  return TagResponseToJSONTyped(json, false);
}

export function TagResponseToJSONTyped(value?: TagResponse | null, ignoreDiscriminator: boolean = false): any {
  if (value == null) {
    return value;
  }

  return {
    id: value['id'],
    name: value['name'],
    colourBackground: value['colourBackground'],
    colourForeground: value['colourForeground'],
    wordCount: value['wordCount'],
    charCount: value['charCount'],
    entries: (value['entries'] as Array<any>).map(EntryMinimalToJSON),
  };
}
