import { notifications } from '@mantine/notifications';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import api from '../../api.ts';
import type { EntryCreateRequest, EntryResponse } from '../../client';

function checkOnline() {
  if (!navigator.onLine) {
    notifications.show({
      autoClose: false,
      id: 'offline',
      title: 'Offline',
      message: 'You are currently offline. Your changes will be saved when you come back online.',
      color: 'yellow',
    });
    return false;
  }

  notifications.hide('offline');
  return true;
}

interface PatchValues {
  patch: { op: string; path: string; value?: unknown }[];
}

interface Variables {
  createValues: EntryCreateRequest;
  id: number | null;
  patchValues: PatchValues;
}

export default function useUpsertEntry(
  setEntry: (entry: EntryResponse) => void,
  setEntryId: (id: number) => void,
  onSuccess?: (entry: EntryResponse) => Promise<void>,
) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (variables: Variables) => {
      if (!checkOnline()) {
        return null;
      }

      const id = variables.id;
      if (id === null) {
        // Don't save empty entries (i.e. if the user just opened the editor and then closed it)
        const values = variables.createValues;
        if (!values.content || values.content.trim().length === 0) {
          return null;
        }

        return await api.createEntry({
          entryCreateRequest: values,
        });
      }

      return await api.patchEntry({
        id: id,
        body: variables.patchValues.patch,
      });
    },
    onError: (error) => {
      console.error(error);
      notifications.show({
        autoClose: false,
        title: 'Error',
        message:
          'Failed to save the entry. Please copy your content, and refresh the page to try saving the entry again!',
        color: 'red',
      });
    },
    onSuccess: async (response) => {
      if (!response) {
        return;
      }

      setEntry(response);
      setEntryId(response.id);
      await queryClient.setQueryData(['api', 'v1', 'entries', response.id], response);

      if (onSuccess) {
        await onSuccess(response);
      }
    },
  });
}
