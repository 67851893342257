import { debounce } from 'lodash';
import { useCallback, useEffect, useRef } from 'react';

type SaveFunction = (content: string, wordsAdded: number) => Promise<void>;

const countNewWords = (previous: string, current: string) => {
  const previousWords = splitIntoWords(previous);
  const currentWords = splitIntoWords(current);

  return Math.max(0, currentWords.length - previousWords.length);
};

const splitIntoWords = (text: string) => {
  return text
    .trim()
    .split(/\s+/)
    .filter((word) => word.length > 0);
};

function useAutoSave(content: string, saveFunction: SaveFunction) {
  const currentContentRef = useRef(content);
  const isSaving = useRef(false);
  const pendingSave = useRef(false);
  const wordsAdded = useRef(0);

  // Update refs whenever content changes
  useEffect(() => {
    const previous = currentContentRef.current;
    const newWords = countNewWords(previous, content);

    wordsAdded.current += newWords;
    currentContentRef.current = content;
    debouncedSave.current();
  }, [content]);

  const saveEntry = useCallback(
    async (content: string) => {
      await saveFunction(content, wordsAdded.current);
      wordsAdded.current = 0;
    },
    [saveFunction],
  );

  // Save function
  const saveContent = useCallback(async () => {
    if (isSaving.current) {
      pendingSave.current = true;
      return;
    }

    isSaving.current = true;
    pendingSave.current = false;

    try {
      await saveEntry(currentContentRef.current);
    } finally {
      isSaving.current = false;
      if (pendingSave.current) {
        console.log('triggered from pendingSave');
        saveContent();
      }
    }
  }, [saveEntry]);

  // Debounced save function for content changes (maxWait 5 seconds)
  const debouncedSave = useRef(debounce(() => saveContent(), 5000, { maxWait: 5000 }));

  // Save every 15 seconds
  useEffect(() => {
    const intervalId = setInterval(() => saveContent(), 15000);
    return () => clearInterval(intervalId);
  }, [saveContent]);

  // Save on blur
  useEffect(() => {
    const handleBlur = () => saveContent();
    window.addEventListener('blur', handleBlur);
    return () => window.removeEventListener('blur', handleBlur);
  }, [saveContent]);

  // Save on visibilitychange
  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.hidden) {
        saveContent();
      }
    };
    document.addEventListener('visibilitychange', handleVisibilityChange);
    return () => document.removeEventListener('visibilitychange', handleVisibilityChange);
  }, [saveContent]);

  // Save on online
  useEffect(() => {
    const handleOnline = () => saveContent();
    window.addEventListener('online', handleOnline);
    return () => window.removeEventListener('online', handleOnline);
  }, [saveContent]);

  // Save beforeunload (when the user tries to close the page)
  // TODO: Be more clever here, and only display the alert if the content has been modified since the last successful save
  // TODO: Implement mobile-friendly unload handling: https://www.igvita.com/2015/11/20/dont-lose-user-and-app-state-use-page-visibility/
  useEffect(() => {
    const handleBeforeUnload = (e: BeforeUnloadEvent) => {
      // Attempt to save content (async operations may not complete)
      saveContent();
      e.preventDefault();
    };
    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => window.removeEventListener('beforeunload', handleBeforeUnload);
  }, [saveContent]);
}

export default useAutoSave;
