import { CalendarIcon, UserIcon } from '@heroicons/react/16/solid';
import { Anchor, AppShell, Container, Group, Text, Title } from '@mantine/core';
import { useQuery } from '@tanstack/react-query';
import Markdown from 'react-markdown';
import api from '../api.ts';
import type { SharedEntryResponse } from '../client';

const dateTimeFormat = new Intl.DateTimeFormat(undefined, {
  year: 'numeric',
  month: 'long',
  day: 'numeric',
});

interface Props {
  id: string;
}

export default function PublicEntryScreen({ id }: Props) {
  const query = useQuery<SharedEntryResponse>({
    queryKey: ['api', 'v1', 'shared', 'entries', id],
    queryFn: () => api.getSharedEntry({ sid: id }),
  });

  if (query.isError) {
    // TODO
    return <div>Error: {query.error.message}</div>;
  }

  if (query.isLoading) {
    return <div>Loading...</div>;
  }

  const entry = query.data;
  if (!entry) {
    return <div>Entry not found</div>;
  }

  const formattedDate = dateTimeFormat.format(new Date(entry.date));

  return (
    <AppShell header={{ height: 70 }} footer={{ height: 60 }} padding="md">
      <AppShell.Header>
        <Container size="lg" h="100%">
          <Group justify="space-between" h="100%">
            <Title order={3}>Writual</Title>
            <Anchor href="https://writual.app" target="_blank" c="dimmed" size="sm" underline="hover">
              Learn more about Writual
            </Anchor>
          </Group>
        </Container>
      </AppShell.Header>

      <AppShell.Main>
        <Container size="md">
          <article>
            <Title order={1} mb="md">
              {entry.title}
            </Title>

            <Group mb="xl">
              <Group gap="xs">
                <CalendarIcon width={16} />
                <Text size="sm" c="dimmed" title={`Created on ${formattedDate}`}>
                  {formattedDate}
                </Text>
              </Group>
              <Group gap="xs">
                <UserIcon width={16} />
                <Text size="sm" c="dimmed" title={`Written by ${entry.author}`}>
                  {entry.author}
                </Text>
              </Group>
            </Group>

            <Text component="div">
              <Markdown>{entry.content}</Markdown>
            </Text>
          </article>
        </Container>
      </AppShell.Main>

      <AppShell.Footer p="md">
        <Text size="sm" ta="center" c="dimmed">
          This entry was published on{' '}
          <a href="https://writual.app" target="_blank" rel="noreferrer">
            Writual
          </a>
          , the place to build your writing habit.
        </Text>
      </AppShell.Footer>
    </AppShell>
  );
}
