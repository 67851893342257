import { Badge, Text, Tooltip } from '@mantine/core';
import { format } from 'date-fns';
import pluralize from 'pluralize';
import type { EntrySeries, EntryTag } from '../client';
import { formatEntryTitle } from '../utils.ts';
import SeriesBadge from './SeriesBadge.tsx';
import { StackedListItem } from './StackedListItem.tsx';
import TagBadge from './TagBadge.tsx';

interface Props {
  entry: {
    id: number;
    title: string | null;
    extract: string;
    date: Date;
    wordCount: number;
    tags: EntryTag[];
    series: EntrySeries | null;
    createdAt: Date;
  };
}

export default function EntryListItem({ entry }: Props) {
  return (
    <StackedListItem
      href={`/entries/${entry.id}`}
      // TODO: This title not descriptive or precise enough
      title={
        <Tooltip label={`Created ${format(entry.createdAt, "'around' h a 'on' EEEE do MMMM yyyy")}`}>
          <span>{formatEntryTitle(entry)}</span>
        </Tooltip>
      }
      titleRight={
        <Badge color="gray" radius="sm" variant="light">
          {entry.wordCount} {pluralize('word', entry.wordCount)}
        </Badge>
      }
      subtitleRight={<TagBadge.Group tags={entry.tags} />}
      subtitle={<SeriesBadge series={entry.series} />}
    >
      <Text size="sm" lineClamp={2}>
        {entry.extract}
      </Text>
    </StackedListItem>
  );
}
