/* tslint:disable */
/* eslint-disable */
/**
 * My App API
 * My App API description
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 *
 * @export
 * @interface GoalPeriod
 */
export interface GoalPeriod {
  /**
   *
   * @type {Date}
   * @memberof GoalPeriod
   */
  start: Date;
  /**
   *
   * @type {Date}
   * @memberof GoalPeriod
   */
  end: Date;
  /**
   *
   * @type {number}
   * @memberof GoalPeriod
   */
  total: number;
  /**
   *
   * @type {number}
   * @memberof GoalPeriod
   */
  progress: number;
  /**
   *
   * @type {boolean}
   * @memberof GoalPeriod
   */
  completed: boolean;
}

/**
 * Check if a given object implements the GoalPeriod interface.
 */
export function instanceOfGoalPeriod(value: object): value is GoalPeriod {
  if (!('start' in value) || value['start'] === undefined) return false;
  if (!('end' in value) || value['end'] === undefined) return false;
  if (!('total' in value) || value['total'] === undefined) return false;
  if (!('progress' in value) || value['progress'] === undefined) return false;
  if (!('completed' in value) || value['completed'] === undefined) return false;
  return true;
}

export function GoalPeriodFromJSON(json: any): GoalPeriod {
  return GoalPeriodFromJSONTyped(json, false);
}

export function GoalPeriodFromJSONTyped(json: any, ignoreDiscriminator: boolean): GoalPeriod {
  if (json == null) {
    return json;
  }
  return {
    start: new Date(json['start']),
    end: new Date(json['end']),
    total: json['total'],
    progress: json['progress'],
    completed: json['completed'],
  };
}

export function GoalPeriodToJSON(json: any): GoalPeriod {
  return GoalPeriodToJSONTyped(json, false);
}

export function GoalPeriodToJSONTyped(value?: GoalPeriod | null, ignoreDiscriminator: boolean = false): any {
  if (value == null) {
    return value;
  }

  return {
    start: value['start'].toISOString().substring(0, 10),
    end: value['end'].toISOString().substring(0, 10),
    total: value['total'],
    progress: value['progress'],
    completed: value['completed'],
  };
}
