/* tslint:disable */
/* eslint-disable */
/**
 * My App API
 * My App API description
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { TagListResponseItem } from './TagListResponseItem';
import {
  TagListResponseItemFromJSON,
  TagListResponseItemFromJSONTyped,
  TagListResponseItemToJSON,
  TagListResponseItemToJSONTyped,
} from './TagListResponseItem';
import type { TagListResponseMeta } from './TagListResponseMeta';
import {
  TagListResponseMetaFromJSON,
  TagListResponseMetaFromJSONTyped,
  TagListResponseMetaToJSON,
  TagListResponseMetaToJSONTyped,
} from './TagListResponseMeta';

/**
 *
 * @export
 * @interface TagListResponse
 */
export interface TagListResponse {
  /**
   *
   * @type {Array<TagListResponseItem>}
   * @memberof TagListResponse
   */
  items: Array<TagListResponseItem>;
  /**
   *
   * @type {TagListResponseMeta}
   * @memberof TagListResponse
   */
  meta: TagListResponseMeta;
}

/**
 * Check if a given object implements the TagListResponse interface.
 */
export function instanceOfTagListResponse(value: object): value is TagListResponse {
  if (!('items' in value) || value['items'] === undefined) return false;
  if (!('meta' in value) || value['meta'] === undefined) return false;
  return true;
}

export function TagListResponseFromJSON(json: any): TagListResponse {
  return TagListResponseFromJSONTyped(json, false);
}

export function TagListResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): TagListResponse {
  if (json == null) {
    return json;
  }
  return {
    items: (json['items'] as Array<any>).map(TagListResponseItemFromJSON),
    meta: TagListResponseMetaFromJSON(json['meta']),
  };
}

export function TagListResponseToJSON(json: any): TagListResponse {
  return TagListResponseToJSONTyped(json, false);
}

export function TagListResponseToJSONTyped(value?: TagListResponse | null, ignoreDiscriminator: boolean = false): any {
  if (value == null) {
    return value;
  }

  return {
    items: (value['items'] as Array<any>).map(TagListResponseItemToJSON),
    meta: TagListResponseMetaToJSON(value['meta']),
  };
}
