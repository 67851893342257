import { useInterval as useMantineInterval } from '@mantine/hooks';
import { useEffect } from 'react';

export default function useInterval(callback: () => Promise<void>, delay: number, interval: number) {
  useMantineInterval(callback, interval, {
    autoInvoke: true,
  });

  useEffect(() => {
    const timeout = setTimeout(() => callback(), delay);
    return () => clearTimeout(timeout);
  }, [callback, delay]);
}
