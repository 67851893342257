import { PlusIcon } from '@heroicons/react/16/solid';
import { Square3Stack3DIcon } from '@heroicons/react/24/outline';
import { Badge, Button } from '@mantine/core';
import { useQuery } from '@tanstack/react-query';
import { Link } from 'react-router-dom';
import api from '../../api';
import type { SeriesListResponse } from '../../client';
import ErrorScreen from '../ErrorScreen.tsx';
import LoadingScreen from '../LoadingScreen';
import NoItemsFound from '../NoItemsFound.tsx';
import Page from '../Page';
import PageTitle from '../PageTitle';
import { StackedListItem } from '../StackedListItem';

export default function SeriesScreen() {
  const query = useQuery<SeriesListResponse>({
    queryKey: ['api', 'v1', 'series'],
    queryFn: () => api.listSeries(),
  });

  if (query.isLoading) {
    return <LoadingScreen />;
  }

  if (query.isError) {
    return <ErrorScreen error={query.error} />;
  }

  const allSeries = query.data?.items || [];

  const series = allSeries.map((serial) => {
    return (
      <StackedListItem
        key={serial.id}
        href={`/series/${serial.id}`}
        title={serial.name}
        titleRight={
          <Badge color="gray" variant="light">
            {serial.wordCount} words
          </Badge>
        }
        subtitle={
          <Badge color="blue" variant="light">
            {serial.entryCount} posts
          </Badge>
        }
        subtitleRight={
          <Badge color="gray" variant="light">
            {serial.charCount} characters
          </Badge>
        }
      />
    );
  });

  return (
    <Page title={<PageTitle title="All series" />}>
      {series}

      {allSeries.length === 0 && (
        <NoItemsFound
          Icon={Square3Stack3DIcon}
          fullHeight
          title="No series found"
          subtitle="It looks like you haven't created any series yet."
          actions={
            // TODO: This route doesn't exist
            <Button component={Link} leftSection={<PlusIcon width={16} />} to="/series/new">
              Create a new serial
            </Button>
          }
        />
      )}
    </Page>
  );
}
