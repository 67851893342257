import { Box, Grid, Stack } from '@mantine/core';
import type React from 'react';
import SelectMode from './SelectMode.tsx';
import SelectSerial from './SelectSerial.tsx';
import SelectTags from './SelectTags.tsx';
import SidebarElement from './SidebarElement.tsx';

interface EditorProps {
  children: React.ReactNode;
}

export default function Editor({ children }: EditorProps) {
  return (
    <Box>
      <Grid>
        <Grid.Col span={{ base: 12, md: 9 }}>{children}</Grid.Col>

        <Grid.Col span={{ base: 12, md: 3 }}>
          <Stack gap={16}>
            <SidebarElement title="Mode" tooltip="Pick a writing mode, based on how you feel like writing today">
              <SelectMode />
            </SidebarElement>

            <SidebarElement title="Series" tooltip="Assign today's entry to a serial, e.g. My Sci-Fi Book">
              <SelectSerial />
            </SidebarElement>

            <SidebarElement title="Tags" tooltip="Assign some tags, which can be used to organise your entries">
              <SelectTags />
            </SidebarElement>
          </Stack>
        </Grid.Col>
      </Grid>
    </Box>
  );
}
