/* tslint:disable */
/* eslint-disable */
/**
 * My App API
 * My App API description
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 *
 * @export
 * @interface TagListResponseMeta
 */
export interface TagListResponseMeta {
  /**
   *
   * @type {number}
   * @memberof TagListResponseMeta
   */
  tagsTotal: number;
}

/**
 * Check if a given object implements the TagListResponseMeta interface.
 */
export function instanceOfTagListResponseMeta(value: object): value is TagListResponseMeta {
  if (!('tagsTotal' in value) || value['tagsTotal'] === undefined) return false;
  return true;
}

export function TagListResponseMetaFromJSON(json: any): TagListResponseMeta {
  return TagListResponseMetaFromJSONTyped(json, false);
}

export function TagListResponseMetaFromJSONTyped(json: any, ignoreDiscriminator: boolean): TagListResponseMeta {
  if (json == null) {
    return json;
  }
  return {
    tagsTotal: json['tagsTotal'],
  };
}

export function TagListResponseMetaToJSON(json: any): TagListResponseMeta {
  return TagListResponseMetaToJSONTyped(json, false);
}

export function TagListResponseMetaToJSONTyped(
  value?: TagListResponseMeta | null,
  ignoreDiscriminator: boolean = false,
): any {
  if (value == null) {
    return value;
  }

  return {
    tagsTotal: value['tagsTotal'],
  };
}
