/* tslint:disable */
/* eslint-disable */
/**
 * My App API
 * My App API description
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 *
 * @export
 * @interface TokenResponse
 */
export interface TokenResponse {
  /**
   *
   * @type {string}
   * @memberof TokenResponse
   */
  token: string;
  /**
   *
   * @type {Date}
   * @memberof TokenResponse
   */
  expiresAt?: Date;
}

/**
 * Check if a given object implements the TokenResponse interface.
 */
export function instanceOfTokenResponse(value: object): value is TokenResponse {
  if (!('token' in value) || value['token'] === undefined) return false;
  return true;
}

export function TokenResponseFromJSON(json: any): TokenResponse {
  return TokenResponseFromJSONTyped(json, false);
}

export function TokenResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): TokenResponse {
  if (json == null) {
    return json;
  }
  return {
    token: json['token'],
    expiresAt: json['expiresAt'] == null ? undefined : new Date(json['expiresAt']),
  };
}

export function TokenResponseToJSON(json: any): TokenResponse {
  return TokenResponseToJSONTyped(json, false);
}

export function TokenResponseToJSONTyped(value?: TokenResponse | null, ignoreDiscriminator: boolean = false): any {
  if (value == null) {
    return value;
  }

  return {
    token: value['token'],
    expiresAt: value['expiresAt'] == null ? undefined : value['expiresAt'].toISOString(),
  };
}
