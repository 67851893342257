import { PlusIcon } from '@heroicons/react/16/solid';
import { Button, Grid, Group, Modal, MultiSelect, TextInput } from '@mantine/core';
import { useForm } from '@mantine/form';
import { useDisclosure } from '@mantine/hooks';
import { notifications } from '@mantine/notifications';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { isEqual } from 'lodash';
import { useEffect } from 'react';
import api from '../../api.ts';
import type { TagListResponse } from '../../client';
import FormSubmit from '../FormSubmit.tsx';
import { useEntry } from './EntryContext.tsx';

interface AddTagRequest {
  name: string;
}

interface AddTagModalProps {
  onSave: (id: number) => void;
}

export function AddTagModal({ onSave }: AddTagModalProps) {
  const queryClient = useQueryClient();
  const [opened, { open, close }] = useDisclosure(false);

  const initialValues: AddTagRequest = {
    name: '',
  };

  const form = useForm({
    mode: 'uncontrolled',
    initialValues,
    validate: {
      name: (value) => (!value ? 'Required' : null),
    },
  });

  const createTag = useMutation({
    mutationFn: (values: typeof form.values) => {
      return api.createTag({
        tagRequest: {
          name: values.name,
        },
      });
    },
    onError: (error) => {
      console.error(error);
      notifications.show({
        title: 'Error',
        message: 'Failed to add tag. Please try again!',
        color: 'red',
      });
    },
    onSuccess: async (tag) => {
      form.reset();
      await queryClient.invalidateQueries({
        queryKey: ['api', 'v1', 'tags'],
      });
      onSave(tag.id);
      close();
    },
  });

  return (
    <>
      <Button variant="subtle" leftSection={<PlusIcon width={16} />} onClick={open}>
        Add
      </Button>

      <Modal centered opened={opened} onClose={close} title="Add new tag">
        <form onSubmit={form.onSubmit((e) => createTag.mutate(e))}>
          <Group grow preventGrowOverflow={false} justify="space-between">
            <TextInput data-autofocus key={form.key('name')} {...form.getInputProps('name')} />
            <FormSubmit loading={createTag.isPending}>Save</FormSubmit>
          </Group>
        </form>
      </Modal>
    </>
  );
}

export default function SelectTags() {
  const { entry, setEntryTags } = useEntry();

  const { initialize, ...form } = useForm({
    // TODO: This should be uncontrolled, but list fields (tags) don't seem to get a value so adding one doesn't update the select box
    mode: 'controlled',
    enhanceGetInputProps: (payload) => {
      if (!payload.form.initialized) {
        return { disabled: true };
      }

      return {};
    },
    onValuesChange: async (values, prev) => {
      if (!isEqual(values.tags, prev.tags)) {
        await setEntryTags(values.tags.map((id: string) => Number.parseInt(id)));
      }
    },
  });

  useEffect(() => {
    if (!form.initialized && entry) {
      initialize({
        tags: entry?.tags.map((t) => t.id.toString()) || [],
      });
    }
  }, [entry, form.initialized, initialize]);

  const query = useQuery<TagListResponse>({
    queryKey: ['api', 'v1', 'tags'],
    queryFn: () => api.listTags(),
  });

  if (query.isError) {
    // TODO
    return <div>Error!</div>;
  }

  if (query.isLoading) {
    // TODO
    return <div>Loading...</div>;
  }

  const tags = query.data;
  if (!tags) {
    // TODO
    return <div>Something went wrong</div>;
  }

  const options = tags?.items.map((t) => ({
    value: t.id.toString(),
    label: t.name,
  }));

  return (
    <Grid>
      <Grid.Col span={8}>
        <MultiSelect
          key={form.key('tags')}
          {...form.getInputProps('tags')}
          data={options}
          placeholder="Pick one or more tags"
          // TODO
          // renderValue={selected => `${selected.length} selected`}
        />
      </Grid.Col>

      <Grid.Col span="auto">
        <AddTagModal onSave={(id) => form.insertListItem('tags', id.toString())} />
      </Grid.Col>
    </Grid>
  );
}
