import { ExclamationTriangleIcon } from '@heroicons/react/24/solid';
import { Alert, Group, NumberInput, Radio } from '@mantine/core';
import { DatePickerInput } from '@mantine/dates';
import { createFormContext } from '@mantine/form';
import { notifications } from '@mantine/notifications';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { isValid } from 'date-fns';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import api from '../../api.ts';
import FormActions from '../FormActions.tsx';
import FormFieldset from '../FormFieldset.tsx';
import FormSubmit from '../FormSubmit.tsx';
import Page from '../Page';
import PageTitle from '../PageTitle';

const [FormProvider, useFormContext, useForm] = createFormContext<NewGoal>();

interface NewGoal {
  type: string;
  target?: number;
  targetDate?: Date;
  recurrence: string;
}

export default function NewGoalScreen() {
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const initialValues: NewGoal = {
    type: '',
    target: undefined,
    targetDate: undefined,
    recurrence: '',
  };

  const form = useForm({
    mode: 'controlled',
    initialValues: initialValues,
    validate: {
      recurrence: (value) => {
        if (!value) {
          return 'A recurrence needs to be selected';
        }

        if (value === 'once' && form.values.type !== 'project_completion') {
          return 'Only project completion goals can be set once';
        }

        if (value !== 'once' && form.values.type === 'project_completion') {
          return 'Project completion goals can only be set once';
        }

        return null;
      },
      type: (value) => {
        if (!value) {
          return 'A type needs to be selected';
        }

        return null;
      },
      target: (value, values) => {
        if (values.type === 'project_completion') {
          return null;
        }

        if (!value) {
          return 'A target needs to be set';
        }

        if (values.type !== 'project_completion' && Number.isNaN(Number(values.target))) {
          return 'The target must be a number';
        }

        return null;
      },
      targetDate: (value, values) => {
        if (values.type !== 'project_completion') {
          return null;
        }

        if (value && !isValid(new Date(value))) {
          return 'The target date must be a valid date in the format YYYY-MM-DD';
        }

        return null;
      },
    },
  });

  form.watch('type', ({ previousValue, value }) => {
    // Reset the target when the type changes
    if (previousValue !== value) {
      form.setFieldValue('target', undefined);
    }
  });

  const createGoal = useMutation({
    mutationFn: (values: typeof form.values) =>
      api.createGoal({
        goalRequest: {
          type: values.type,
          target: values.target,
          targetDate: values.targetDate,
          recurrence: values.recurrence,
        },
      }),
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ['api', 'v1', 'goals'],
      });
      notifications.show({
        title: 'Goal created',
        message: 'Your goal was created!',
        color: 'green',
      });
      navigate('/goals');
    },
    onError: (error) => {
      console.error(error);
      notifications.show({
        title: 'Error',
        message: 'An error occurred while creating your goal. Please try again!',
        color: 'red',
      });
    },
  });

  return (
    <Page title={<PageTitle title="Set a new goal" />}>
      <Alert color="yellow" title="Warning" icon={<ExclamationTriangleIcon />} mb={16}>
        Goals are still in development and may not work as expected!
      </Alert>

      <FormProvider form={form}>
        <form onSubmit={form.onSubmit((e) => createGoal.mutate(e))}>
          <FormFieldset>
            <Radio.Group key={form.key('type')} {...form.getInputProps('type')} label="Type" required>
              <Group>
                <Radio value="word_count" label="Word count" description="Aim to write a certain number of words" />
                <Radio value="entry_count" label="Entry count" description="Aim to write a certain number of entries" />
                <Radio
                  value="project_completion"
                  label="Project completion"
                  description="Complete a project before an optional deadline"
                />
              </Group>
            </Radio.Group>

            {form.values.type === 'project_completion' && (
              <DatePickerInput
                key={form.key('targetDate')}
                {...form.getInputProps('targetDate')}
                label="Target date"
                minDate={new Date()}
              />
            )}

            {form.values.type !== 'project_completion' && (
              <NumberInput key={form.key('target')} {...form.getInputProps('target')} label="Target" min={1} step={1} />
            )}

            <GoalTypeRadioGroup />
          </FormFieldset>

          <FormActions>
            <FormSubmit loading={createGoal.isPending}>Save</FormSubmit>
          </FormActions>
        </form>
      </FormProvider>
    </Page>
  );
}

function GoalTypeRadioGroup() {
  const form = useFormContext();

  const [type, setType] = useState(form.values.type);
  form.watch('type', ({ value }) => {
    setType(value);
  });

  form.watch('recurrence', ({ value }) => {
    if (value === 'once' && type !== 'project_completion') {
      form.setFieldValue('recurrence', 'daily');
    } else if (value !== 'once' && type === 'project_completion') {
      form.setFieldValue('recurrence', 'once');
    }
  });

  return (
    <Radio.Group key={form.key('recurrence')} {...form.getInputProps('recurrence')} label="Recurrence">
      <Radio disabled={type !== 'project_completion'} value="once" label="Once" description="A one-time goal" />

      <Radio
        disabled={type === 'project_completion'}
        value="daily"
        label="Daily"
        description="Reset this goal every day"
      />

      <Radio
        disabled={type === 'project_completion'}
        value="weekly"
        label="Weekly"
        description="Reset this goal every week, on Monday"
      />

      <Radio
        disabled={type === 'project_completion'}
        value="monthly"
        label="Monthly"
        description="Reset this goal every month, on the 1st"
      />
    </Radio.Group>
  );
}
