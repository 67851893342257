/* tslint:disable */
/* eslint-disable */
/**
 * My App API
 * My App API description
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { SeriesListResponseItem } from './SeriesListResponseItem';
import {
  SeriesListResponseItemFromJSON,
  SeriesListResponseItemFromJSONTyped,
  SeriesListResponseItemToJSON,
  SeriesListResponseItemToJSONTyped,
} from './SeriesListResponseItem';
import type { SeriesListResponseMeta } from './SeriesListResponseMeta';
import {
  SeriesListResponseMetaFromJSON,
  SeriesListResponseMetaFromJSONTyped,
  SeriesListResponseMetaToJSON,
  SeriesListResponseMetaToJSONTyped,
} from './SeriesListResponseMeta';

/**
 *
 * @export
 * @interface SeriesListResponse
 */
export interface SeriesListResponse {
  /**
   *
   * @type {Array<SeriesListResponseItem>}
   * @memberof SeriesListResponse
   */
  items: Array<SeriesListResponseItem>;
  /**
   *
   * @type {SeriesListResponseMeta}
   * @memberof SeriesListResponse
   */
  meta: SeriesListResponseMeta;
}

/**
 * Check if a given object implements the SeriesListResponse interface.
 */
export function instanceOfSeriesListResponse(value: object): value is SeriesListResponse {
  if (!('items' in value) || value['items'] === undefined) return false;
  if (!('meta' in value) || value['meta'] === undefined) return false;
  return true;
}

export function SeriesListResponseFromJSON(json: any): SeriesListResponse {
  return SeriesListResponseFromJSONTyped(json, false);
}

export function SeriesListResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): SeriesListResponse {
  if (json == null) {
    return json;
  }
  return {
    items: (json['items'] as Array<any>).map(SeriesListResponseItemFromJSON),
    meta: SeriesListResponseMetaFromJSON(json['meta']),
  };
}

export function SeriesListResponseToJSON(json: any): SeriesListResponse {
  return SeriesListResponseToJSONTyped(json, false);
}

export function SeriesListResponseToJSONTyped(
  value?: SeriesListResponse | null,
  ignoreDiscriminator: boolean = false,
): any {
  if (value == null) {
    return value;
  }

  return {
    items: (value['items'] as Array<any>).map(SeriesListResponseItemToJSON),
    meta: SeriesListResponseMetaToJSON(value['meta']),
  };
}
