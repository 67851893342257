import { EyeIcon, LockClosedIcon } from '@heroicons/react/16/solid';
import { ClipboardDocumentCheckIcon, ClipboardDocumentIcon } from '@heroicons/react/20/solid';
import {
  BookOpenIcon,
  CalculatorIcon,
  CalendarIcon,
  DocumentTextIcon,
  ShareIcon,
  TagIcon,
} from '@heroicons/react/24/outline';
import { PencilIcon } from '@heroicons/react/24/solid';
import { ActionIcon, Button, Flex, Grid, Group, Modal, Stack, Switch, Text, TextInput, Tooltip } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { notifications } from '@mantine/notifications';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import pluralize from 'pluralize';
import { type SVGProps, useState } from 'react';
import Markdown from 'react-markdown';
import { Link } from 'react-router-dom';
import Sheet from '../../Sheet.tsx';
import api from '../../api.ts';
import { type EntryResponse, EntryResponseVisibilityEnum } from '../../client';
import useInterval from '../../hooks/useInterval.ts';
import { formatDate, formatEntryTitle } from '../../utils.ts';
import Page from '../Page';
import PageTitle from '../PageTitle';
import SeriesBadge from '../SeriesBadge.tsx';
import TagBadge from '../TagBadge.tsx';

interface EntryMetaItemProps {
  children: React.ReactNode;
  tooltip: string;
  Icon: React.ElementType<SVGProps<SVGSVGElement>>;
}

function EntryMetaItem({ children, tooltip, Icon }: EntryMetaItemProps) {
  return (
    <Grid align="center" justify="center">
      <Grid.Col span="content">
        <Tooltip label={tooltip}>
          <Flex align="center">
            <Icon width={18} />
          </Flex>
        </Tooltip>
      </Grid.Col>

      <Grid.Col span="auto">{children}</Grid.Col>
    </Grid>
  );
}

interface EntryShareModalProps {
  entry: EntryResponse;
}

function EntryShareModal({ entry }: EntryShareModalProps) {
  const queryClient = useQueryClient();
  const [visibility, setVisibility] = useState<EntryResponseVisibilityEnum>(entry.visibility);
  const [opened, { open, close }] = useDisclosure(false);
  const [copied, setCopied] = useState(false);
  const [sid, setSid] = useState(entry.sid);

  const mutateVisibility = useMutation({
    mutationFn: async (visibility: EntryResponseVisibilityEnum) => {
      if (visibility === EntryResponseVisibilityEnum.Private) {
        setSid('');
      }

      const response = await api.patchEntry({
        id: entry.id,
        body: [
          {
            op: 'replace',
            path: '/visibility',
            value: visibility,
          },
        ],
      });

      // TODO: This shouldn't be necessary - the query invalidation should cause the entry to be re-fetched
      if (response.sid) {
        setSid(`${window.location.protocol}//${window.location.host}/s/${response.sid}`);
      }

      await queryClient.invalidateQueries({
        queryKey: ['api', 'v1', 'entries', entry.id],
      });
    },
  });

  const updateVisibility = async (checked: boolean) => {
    setVisibility(checked ? EntryResponseVisibilityEnum.Public : EntryResponseVisibilityEnum.Private);
    await mutateVisibility.mutateAsync(
      checked ? EntryResponseVisibilityEnum.Public : EntryResponseVisibilityEnum.Private,
    );
  };

  const copyToClipboard = async () => {
    if (sid) {
      await navigator.clipboard.writeText(sid);
      setCopied(true);
      setTimeout(() => setCopied(false), 2000);
      notifications.show({
        title: 'Link copied to clipboard',
        message: 'You can now share this link with others',
        color: 'blue',
      });
    }
  };

  return (
    <>
      <Button leftSection={<ShareIcon width={16} />} onClick={open} variant="outline">
        Share entry
      </Button>

      <Modal centered onClose={close} opened={opened} title="Share entry" size="lg">
        <Stack>
          <Switch
            defaultChecked={visibility === EntryResponseVisibilityEnum.Public}
            description={
              visibility === EntryResponseVisibilityEnum.Public
                ? 'Anyone with the link can view this entry'
                : 'Only you can view this entry'
            }
            label={visibility === EntryResponseVisibilityEnum.Public ? 'Public' : 'Private'}
            offLabel={<LockClosedIcon width={12} />}
            onLabel={<EyeIcon width={12} />}
            onChange={(e) => updateVisibility(e.target.checked)}
          />

          <Grid>
            <Grid.Col span="auto">
              <TextInput disabled={visibility === EntryResponseVisibilityEnum.Private} readOnly size="sm" value={sid} />
            </Grid.Col>
            <Grid.Col span="content">
              <ActionIcon
                disabled={visibility === EntryResponseVisibilityEnum.Private}
                loading={mutateVisibility.isPending}
                onClick={copyToClipboard}
                size="input-sm"
                variant="filled"
                aria-label="Copy sharing link to clipboard"
              >
                {copied ? <ClipboardDocumentCheckIcon width={20} /> : <ClipboardDocumentIcon width={20} />}
              </ActionIcon>
            </Grid.Col>
          </Grid>
        </Stack>
      </Modal>
    </>
  );
}

interface EntryViewProps {
  entry?: EntryResponse;
}

export const EntryView = ({ entry }: EntryViewProps) => {
  const [token, setToken] = useState<string | null>(null);

  useInterval(
    () =>
      // TODO: This happens 3 times on page load
      api
        .createToken()
        .then((data) => {
          setToken(data.token);
        }),
    0,
    1000 * 60 * 30,
  );

  if (entry) {
    return (
      <Page
        title={
          <Group justify="space-between" align="center">
            <PageTitle title={formatEntryTitle(entry)} />

            <Group>
              <EntryShareModal entry={entry} />

              <Button
                component="a"
                href={`/api/v1/entries/${entry.id}/pdf?token=${token}`}
                leftSection={<DocumentTextIcon width={16} />}
                loading={token === null}
                target="_blank"
                variant="outline"
              >
                View as PDF
              </Button>

              <Button component={Link} to={`/entries/${entry.id}/edit`} leftSection={<PencilIcon width={16} />}>
                Edit
              </Button>
            </Group>
          </Group>
        }
      >
        <Stack>
          <Sheet p={16}>
            <Stack gap={8}>
              <EntryMetaItem Icon={CalendarIcon} tooltip="Entry date">
                <Text size="sm">{formatDate(entry.date)}</Text>
              </EntryMetaItem>

              <EntryMetaItem Icon={CalculatorIcon} tooltip="Word count">
                <Text size="sm">
                  {entry.wordCount} {pluralize('word', entry.wordCount)}
                </Text>
              </EntryMetaItem>

              <EntryMetaItem Icon={BookOpenIcon} tooltip="Entry series">
                <SeriesBadge showIcon={false} showOnlyName={true} series={entry.series} />
              </EntryMetaItem>

              <EntryMetaItem Icon={TagIcon} tooltip="Entry tags">
                <TagBadge.Group tags={entry.tags} />
              </EntryMetaItem>
            </Stack>
          </Sheet>

          <Text component="div" size="sm">
            <Markdown>{entry.content}</Markdown>
          </Text>
        </Stack>
      </Page>
    );
  }

  // TODO: This is basically the 404 screen now
  return (
    <>
      <h3>🙁 Nothing here</h3>
      <p>
        You didn't make an entry on this date! Make sure you've <Link to="/"> written something for today</Link>.
      </p>
    </>
  );
};
