import './app.css';
import { useAuthenticator } from '@aws-amplify/ui-react';
import * as Sentry from '@sentry/react';
import { Outlet, RouterProvider, createBrowserRouter, redirect } from 'react-router-dom';
import Header from './components/Header';
import { ProvideAuth } from './hooks/useAuth';
import '@mantine/core/styles.css';
import '@mantine/dates/styles.css';
import '@mantine/notifications/styles.css';
import { AppShell } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { Notifications } from '@mantine/notifications';
import { Hub } from 'aws-amplify/utils';
import { useEffect } from 'react';
import EntriesScreen from './components/EntriesScreen';
import EntryEditScreen from './components/EntryEditScreen';
import EntryScreen from './components/EntryScreen';
import GoalsScreen from './components/GoalsScreen';
import LoginScreen from './components/LoginScreen.tsx';
import NewEntryScreen from './components/NewEntryScreen';
import NewGoalScreen from './components/NewGoalScreen';
import ProfileScreen from './components/ProfileScreen';
import PublicEntryScreen from './components/PublicEntryScreen.tsx';
import SerialScreen from './components/SerialScreen';
import SeriesScreen from './components/SeriesScreen';
import SettingsScreen from './components/SettingsScreen';
import StatsScreen from './components/StatsScreen';
import TagScreen from './components/TagScreen';
import TagsScreen from './components/TagsScreen';
import TodayScreen from './components/TodayScreen';

const sentryCreateBrowserRouter = Sentry.wrapCreateBrowserRouter(createBrowserRouter);

const AppContent = () => {
  const [opened, { toggle }] = useDisclosure();
  const { signOut } = useAuthenticator((context) => [context.signOut]);

  return (
    <ProvideAuth>
      <Notifications />
      <AppShell
        header={{ height: 70 }}
        navbar={{ width: 300, breakpoint: 'sm', collapsed: { desktop: true, mobile: !opened } }}
        padding="md"
      >
        <Header navbarOpened={opened} navbarToggle={toggle} signOut={signOut} />

        <AppShell.Main>
          <Outlet />
        </AppShell.Main>
      </AppShell>
    </ProvideAuth>
  );
};

const router = sentryCreateBrowserRouter([
  {
    path: '/',
    element: <AppContent />,
    children: [
      {
        path: '/',
        element: <TodayScreen />,
      },
      {
        path: 'entries',
        element: <EntriesScreen />,
      },
      {
        path: 'entries/new',
        element: <NewEntryScreen />,
      },
      {
        path: 'entries/:id',
        element: <EntryScreen />,
      },
      {
        path: 'entries/:id/edit',
        element: <EntryEditScreen />,
      },
      {
        path: 'goals',
        element: <GoalsScreen />,
      },
      {
        path: 'goals/new',
        element: <NewGoalScreen />,
      },
      {
        path: 'profile',
        element: <ProfileScreen />,
      },
      {
        path: 'series',
        element: <SeriesScreen />,
      },
      {
        path: 'series/:id',
        element: <SerialScreen />,
      },
      {
        path: 'settings',
        element: <SettingsScreen />,
      },
      {
        path: 'tags',
        element: <TagsScreen />,
      },
      {
        path: 'tags/:id',
        element: <TagScreen />,
      },
      {
        path: 'stats',
        element: <StatsScreen />,
      },
    ],
  },
]);

// TODO: Make this use authenticated routes instead, so if a logged-out user visits e.g. /entries, they can just log in rather than get a 404
// const unauthenticatedRouter = sentryCreateBrowserRouter([
//   {
//     path: '/',
//     element: <PublicShell />,
//     children: [
//       {
//         path: '/',
//         element: <LandingScreen />,
//       },
//       {
//         path: 'login',
//         element: <LoginScreen />,
//       },
//     ],
//   },
// ]);

export function App() {
  const { user } = useAuthenticator((context) => [context.user]);

  useEffect(() => {
    Hub.listen('auth', ({ payload }) => {
      switch (payload.event) {
        case 'signedIn':
          console.log('user has been signedIn.');
          redirect('/');
          break;
        case 'signedOut':
          console.log('user has been signedOut.');
          redirect('/');
          break;
      }
    });
  }, []);

  if (window.location.pathname.startsWith('/s/')) {
    const id = window.location.pathname.split('/').pop();
    if (!id) {
      // TODO
      return <div>Invalid short URL</div>;
    }

    return <PublicEntryScreen id={id} />;
  }

  if (user) {
    return <RouterProvider router={router} />;
  }

  return <LoginScreen />;
  // return <RouterProvider router={unauthenticatedRouter} />;
}
