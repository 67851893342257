import { TextInput } from '@mantine/core';
import { useForm } from '@mantine/form';
import { useDebouncedCallback } from '@mantine/hooks';
import { useEffect } from 'react';
import { useEntry } from './EntryContext.tsx';

export default function EntryTitle() {
  const { entry, setEntryTitle } = useEntry();

  const handlePatchEntry = useDebouncedCallback(setEntryTitle, 500);

  const { initialize, ...form } = useForm({
    mode: 'controlled',
    onValuesChange: async (values, prev) => {
      if (values.title !== prev.title) {
        handlePatchEntry(values.title);
      }
    },
  });

  useEffect(() => {
    if (entry) {
      initialize({
        title: entry.title,
      });
    }
  }, [entry, initialize]);

  return (
    <TextInput
      key={form.key('title')}
      {...form.getInputProps('title')}
      placeholder="Enter a title for your entry (optional)"
    />
  );
}
