/* tslint:disable */
/* eslint-disable */
/**
 * My App API
 * My App API description
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Series } from './Series';
import { SeriesFromJSON, SeriesFromJSONTyped, SeriesToJSON, SeriesToJSONTyped } from './Series';
import type { Tag } from './Tag';
import { TagFromJSON, TagFromJSONTyped, TagToJSON, TagToJSONTyped } from './Tag';

/**
 *
 * @export
 * @interface EntryCreateRequest
 */
export interface EntryCreateRequest {
  /**
   *
   * @type {Date}
   * @memberof EntryCreateRequest
   */
  date?: Date;
  /**
   *
   * @type {string}
   * @memberof EntryCreateRequest
   */
  title?: string | null;
  /**
   *
   * @type {string}
   * @memberof EntryCreateRequest
   */
  type?: EntryCreateRequestTypeEnum;
  /**
   *
   * @type {string}
   * @memberof EntryCreateRequest
   */
  content?: string;
  /**
   *
   * @type {Array<Tag>}
   * @memberof EntryCreateRequest
   */
  tags?: Array<Tag>;
  /**
   *
   * @type {Series}
   * @memberof EntryCreateRequest
   */
  series?: Series | null;
  /**
   *
   * @type {string}
   * @memberof EntryCreateRequest
   */
  visibility?: EntryCreateRequestVisibilityEnum;
}

/**
 * @export
 */
export const EntryCreateRequestTypeEnum = {
  Freeform: 'freeform',
  Prompt: 'prompt',
} as const;
export type EntryCreateRequestTypeEnum = (typeof EntryCreateRequestTypeEnum)[keyof typeof EntryCreateRequestTypeEnum];

/**
 * @export
 */
export const EntryCreateRequestVisibilityEnum = {
  Private: 'private',
  Public: 'public',
} as const;
export type EntryCreateRequestVisibilityEnum =
  (typeof EntryCreateRequestVisibilityEnum)[keyof typeof EntryCreateRequestVisibilityEnum];

/**
 * Check if a given object implements the EntryCreateRequest interface.
 */
export function instanceOfEntryCreateRequest(value: object): value is EntryCreateRequest {
  return true;
}

export function EntryCreateRequestFromJSON(json: any): EntryCreateRequest {
  return EntryCreateRequestFromJSONTyped(json, false);
}

export function EntryCreateRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): EntryCreateRequest {
  if (json == null) {
    return json;
  }
  return {
    date: json['date'] == null ? undefined : new Date(json['date']),
    title: json['title'] == null ? undefined : json['title'],
    type: json['type'] == null ? undefined : json['type'],
    content: json['content'] == null ? undefined : json['content'],
    tags: json['tags'] == null ? undefined : (json['tags'] as Array<any>).map(TagFromJSON),
    series: json['series'] == null ? undefined : SeriesFromJSON(json['series']),
    visibility: json['visibility'] == null ? undefined : json['visibility'],
  };
}

export function EntryCreateRequestToJSON(json: any): EntryCreateRequest {
  return EntryCreateRequestToJSONTyped(json, false);
}

export function EntryCreateRequestToJSONTyped(
  value?: EntryCreateRequest | null,
  ignoreDiscriminator: boolean = false,
): any {
  if (value == null) {
    return value;
  }

  return {
    date: value['date'] == null ? undefined : value['date'].toISOString().substring(0, 10),
    title: value['title'],
    type: value['type'],
    content: value['content'],
    tags: value['tags'] == null ? undefined : (value['tags'] as Array<any>).map(TagToJSON),
    series: SeriesToJSON(value['series']),
    visibility: value['visibility'],
  };
}
