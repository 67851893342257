/* tslint:disable */
/* eslint-disable */
/**
 * My App API
 * My App API description
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 *
 * @export
 * @interface SeriesListResponseMeta
 */
export interface SeriesListResponseMeta {
  /**
   *
   * @type {number}
   * @memberof SeriesListResponseMeta
   */
  seriesTotal: number;
}

/**
 * Check if a given object implements the SeriesListResponseMeta interface.
 */
export function instanceOfSeriesListResponseMeta(value: object): value is SeriesListResponseMeta {
  if (!('seriesTotal' in value) || value['seriesTotal'] === undefined) return false;
  return true;
}

export function SeriesListResponseMetaFromJSON(json: any): SeriesListResponseMeta {
  return SeriesListResponseMetaFromJSONTyped(json, false);
}

export function SeriesListResponseMetaFromJSONTyped(json: any, ignoreDiscriminator: boolean): SeriesListResponseMeta {
  if (json == null) {
    return json;
  }
  return {
    seriesTotal: json['seriesTotal'],
  };
}

export function SeriesListResponseMetaToJSON(json: any): SeriesListResponseMeta {
  return SeriesListResponseMetaToJSONTyped(json, false);
}

export function SeriesListResponseMetaToJSONTyped(
  value?: SeriesListResponseMeta | null,
  ignoreDiscriminator: boolean = false,
): any {
  if (value == null) {
    return value;
  }

  return {
    seriesTotal: value['seriesTotal'],
  };
}
