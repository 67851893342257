import { InformationCircleIcon } from '@heroicons/react/16/solid';
import { Group, Stack, Text, Tooltip } from '@mantine/core';
import type React from 'react';

interface SidebarElementProps {
  children: React.ReactNode;
  title: string;
  tooltip: React.ReactNode;
}

export default function SidebarElement({ children, title, tooltip }: SidebarElementProps) {
  return (
    <Stack gap={12}>
      <Tooltip label={tooltip}>
        <Group gap={8}>
          <InformationCircleIcon width={16} />

          <Text fw={600} size="sm">
            {title}
          </Text>
        </Group>
      </Tooltip>

      {children}
    </Stack>
  );
}
