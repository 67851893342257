/* tslint:disable */
/* eslint-disable */
/**
 * My App API
 * My App API description
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 *
 * @export
 * @interface TagListResponseItem
 */
export interface TagListResponseItem {
  /**
   *
   * @type {number}
   * @memberof TagListResponseItem
   */
  id: number;
  /**
   *
   * @type {string}
   * @memberof TagListResponseItem
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof TagListResponseItem
   */
  colourBackground: string;
  /**
   *
   * @type {string}
   * @memberof TagListResponseItem
   */
  colourForeground: string;
  /**
   *
   * @type {number}
   * @memberof TagListResponseItem
   */
  wordCount: number;
  /**
   *
   * @type {number}
   * @memberof TagListResponseItem
   */
  charCount: number;
  /**
   *
   * @type {number}
   * @memberof TagListResponseItem
   */
  entryCount: number;
}

/**
 * Check if a given object implements the TagListResponseItem interface.
 */
export function instanceOfTagListResponseItem(value: object): value is TagListResponseItem {
  if (!('id' in value) || value['id'] === undefined) return false;
  if (!('name' in value) || value['name'] === undefined) return false;
  if (!('colourBackground' in value) || value['colourBackground'] === undefined) return false;
  if (!('colourForeground' in value) || value['colourForeground'] === undefined) return false;
  if (!('wordCount' in value) || value['wordCount'] === undefined) return false;
  if (!('charCount' in value) || value['charCount'] === undefined) return false;
  if (!('entryCount' in value) || value['entryCount'] === undefined) return false;
  return true;
}

export function TagListResponseItemFromJSON(json: any): TagListResponseItem {
  return TagListResponseItemFromJSONTyped(json, false);
}

export function TagListResponseItemFromJSONTyped(json: any, ignoreDiscriminator: boolean): TagListResponseItem {
  if (json == null) {
    return json;
  }
  return {
    id: json['id'],
    name: json['name'],
    colourBackground: json['colourBackground'],
    colourForeground: json['colourForeground'],
    wordCount: json['wordCount'],
    charCount: json['charCount'],
    entryCount: json['entryCount'],
  };
}

export function TagListResponseItemToJSON(json: any): TagListResponseItem {
  return TagListResponseItemToJSONTyped(json, false);
}

export function TagListResponseItemToJSONTyped(
  value?: TagListResponseItem | null,
  ignoreDiscriminator: boolean = false,
): any {
  if (value == null) {
    return value;
  }

  return {
    id: value['id'],
    name: value['name'],
    colourBackground: value['colourBackground'],
    colourForeground: value['colourForeground'],
    wordCount: value['wordCount'],
    charCount: value['charCount'],
    entryCount: value['entryCount'],
  };
}
