import { useQuery } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';
import api from '../../api';
import type { TagResponse } from '../../client';
import EntryListItem from '../EntryListItem.tsx';
import ErrorScreen from '../ErrorScreen.tsx';
import LoadingScreen from '../LoadingScreen';
import Page from '../Page';
import PageTitle from '../PageTitle';

type TagScreenParams = {
  id: string;
};

export default function TagScreen() {
  const { id } = useParams<keyof TagScreenParams>() as TagScreenParams;
  const query = useQuery<TagResponse>({
    queryKey: ['api', 'v1', 'tags', id],
    queryFn: () =>
      api.getTag({
        id: Number.parseInt(id),
      }),
  });

  if (query.isLoading) {
    return <LoadingScreen />;
  }

  if (query.isError) {
    return <ErrorScreen error={query.error} />;
  }

  const tag = query.data;
  if (!tag) {
    return <div>Tag not found</div>;
  }

  return (
    <Page title={<PageTitle title={tag.name} />}>
      {tag.entries.map((entry) => {
        return <EntryListItem key={entry.id} entry={entry} />;
      })}
    </Page>
  );
}
