/* tslint:disable */
/* eslint-disable */
/**
 * My App API
 * My App API description
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { GoalPeriod } from './GoalPeriod';
import { GoalPeriodFromJSON, GoalPeriodFromJSONTyped, GoalPeriodToJSON, GoalPeriodToJSONTyped } from './GoalPeriod';

/**
 *
 * @export
 * @interface GoalResponse
 */
export interface GoalResponse {
  /**
   *
   * @type {number}
   * @memberof GoalResponse
   */
  id: number;
  /**
   *
   * @type {string}
   * @memberof GoalResponse
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof GoalResponse
   */
  type: string;
  /**
   *
   * @type {number}
   * @memberof GoalResponse
   */
  target: number;
  /**
   *
   * @type {Date}
   * @memberof GoalResponse
   */
  targetDate?: Date;
  /**
   *
   * @type {string}
   * @memberof GoalResponse
   */
  recurrence: string;
  /**
   *
   * @type {boolean}
   * @memberof GoalResponse
   */
  completed: boolean;
  /**
   *
   * @type {Array<GoalPeriod>}
   * @memberof GoalResponse
   */
  periods: Array<GoalPeriod>;
}

/**
 * Check if a given object implements the GoalResponse interface.
 */
export function instanceOfGoalResponse(value: object): value is GoalResponse {
  if (!('id' in value) || value['id'] === undefined) return false;
  if (!('name' in value) || value['name'] === undefined) return false;
  if (!('type' in value) || value['type'] === undefined) return false;
  if (!('target' in value) || value['target'] === undefined) return false;
  if (!('recurrence' in value) || value['recurrence'] === undefined) return false;
  if (!('completed' in value) || value['completed'] === undefined) return false;
  if (!('periods' in value) || value['periods'] === undefined) return false;
  return true;
}

export function GoalResponseFromJSON(json: any): GoalResponse {
  return GoalResponseFromJSONTyped(json, false);
}

export function GoalResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GoalResponse {
  if (json == null) {
    return json;
  }
  return {
    id: json['id'],
    name: json['name'],
    type: json['type'],
    target: json['target'],
    targetDate: json['targetDate'] == null ? undefined : new Date(json['targetDate']),
    recurrence: json['recurrence'],
    completed: json['completed'],
    periods: (json['periods'] as Array<any>).map(GoalPeriodFromJSON),
  };
}

export function GoalResponseToJSON(json: any): GoalResponse {
  return GoalResponseToJSONTyped(json, false);
}

export function GoalResponseToJSONTyped(value?: GoalResponse | null, ignoreDiscriminator: boolean = false): any {
  if (value == null) {
    return value;
  }

  return {
    id: value['id'],
    name: value['name'],
    type: value['type'],
    target: value['target'],
    targetDate: value['targetDate'] == null ? undefined : value['targetDate'].toISOString().substring(0, 10),
    recurrence: value['recurrence'],
    completed: value['completed'],
    periods: (value['periods'] as Array<any>).map(GoalPeriodToJSON),
  };
}
