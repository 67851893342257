/* tslint:disable */
/* eslint-disable */
/**
 * My App API
 * My App API description
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 *
 * @export
 * @interface SettingsResponse
 */
export interface SettingsResponse {
  /**
   *
   * @type {number}
   * @memberof SettingsResponse
   */
  reminder?: number;
}

/**
 * Check if a given object implements the SettingsResponse interface.
 */
export function instanceOfSettingsResponse(value: object): value is SettingsResponse {
  return true;
}

export function SettingsResponseFromJSON(json: any): SettingsResponse {
  return SettingsResponseFromJSONTyped(json, false);
}

export function SettingsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): SettingsResponse {
  if (json == null) {
    return json;
  }
  return {
    reminder: json['reminder'] == null ? undefined : json['reminder'],
  };
}

export function SettingsResponseToJSON(json: any): SettingsResponse {
  return SettingsResponseToJSONTyped(json, false);
}

export function SettingsResponseToJSONTyped(
  value?: SettingsResponse | null,
  ignoreDiscriminator: boolean = false,
): any {
  if (value == null) {
    return value;
  }

  return {
    reminder: value['reminder'],
  };
}
