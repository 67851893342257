/* tslint:disable */
/* eslint-disable */
/**
 * My App API
 * My App API description
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 *
 * @export
 * @interface SeriesRequest
 */
export interface SeriesRequest {
  /**
   *
   * @type {string}
   * @memberof SeriesRequest
   */
  name?: string;
}

/**
 * Check if a given object implements the SeriesRequest interface.
 */
export function instanceOfSeriesRequest(value: object): value is SeriesRequest {
  return true;
}

export function SeriesRequestFromJSON(json: any): SeriesRequest {
  return SeriesRequestFromJSONTyped(json, false);
}

export function SeriesRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): SeriesRequest {
  if (json == null) {
    return json;
  }
  return {
    name: json['name'] == null ? undefined : json['name'],
  };
}

export function SeriesRequestToJSON(json: any): SeriesRequest {
  return SeriesRequestToJSONTyped(json, false);
}

export function SeriesRequestToJSONTyped(value?: SeriesRequest | null, ignoreDiscriminator: boolean = false): any {
  if (value == null) {
    return value;
  }

  return {
    name: value['name'],
  };
}
