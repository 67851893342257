/* tslint:disable */
/* eslint-disable */
/**
 * My App API
 * My App API description
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { EntrySeries } from './EntrySeries';
import {
  EntrySeriesFromJSON,
  EntrySeriesFromJSONTyped,
  EntrySeriesToJSON,
  EntrySeriesToJSONTyped,
} from './EntrySeries';
import type { EntryTag } from './EntryTag';
import { EntryTagFromJSON, EntryTagFromJSONTyped, EntryTagToJSON, EntryTagToJSONTyped } from './EntryTag';

/**
 *
 * @export
 * @interface EntryMinimal
 */
export interface EntryMinimal {
  /**
   *
   * @type {number}
   * @memberof EntryMinimal
   */
  id: number;
  /**
   *
   * @type {string}
   * @memberof EntryMinimal
   */
  title: string;
  /**
   *
   * @type {Date}
   * @memberof EntryMinimal
   */
  date: Date;
  /**
   *
   * @type {string}
   * @memberof EntryMinimal
   */
  extract: string;
  /**
   *
   * @type {number}
   * @memberof EntryMinimal
   */
  wordCount: number;
  /**
   *
   * @type {number}
   * @memberof EntryMinimal
   */
  charCount: number;
  /**
   *
   * @type {Date}
   * @memberof EntryMinimal
   */
  createdAt: Date;
  /**
   *
   * @type {Array<EntryTag>}
   * @memberof EntryMinimal
   */
  tags: Array<EntryTag>;
  /**
   *
   * @type {EntrySeries}
   * @memberof EntryMinimal
   */
  series: EntrySeries;
}

/**
 * Check if a given object implements the EntryMinimal interface.
 */
export function instanceOfEntryMinimal(value: object): value is EntryMinimal {
  if (!('id' in value) || value['id'] === undefined) return false;
  if (!('title' in value) || value['title'] === undefined) return false;
  if (!('date' in value) || value['date'] === undefined) return false;
  if (!('extract' in value) || value['extract'] === undefined) return false;
  if (!('wordCount' in value) || value['wordCount'] === undefined) return false;
  if (!('charCount' in value) || value['charCount'] === undefined) return false;
  if (!('createdAt' in value) || value['createdAt'] === undefined) return false;
  if (!('tags' in value) || value['tags'] === undefined) return false;
  if (!('series' in value) || value['series'] === undefined) return false;
  return true;
}

export function EntryMinimalFromJSON(json: any): EntryMinimal {
  return EntryMinimalFromJSONTyped(json, false);
}

export function EntryMinimalFromJSONTyped(json: any, ignoreDiscriminator: boolean): EntryMinimal {
  if (json == null) {
    return json;
  }
  return {
    id: json['id'],
    title: json['title'],
    date: new Date(json['date']),
    extract: json['extract'],
    wordCount: json['wordCount'],
    charCount: json['charCount'],
    createdAt: new Date(json['createdAt']),
    tags: (json['tags'] as Array<any>).map(EntryTagFromJSON),
    series: EntrySeriesFromJSON(json['series']),
  };
}

export function EntryMinimalToJSON(json: any): EntryMinimal {
  return EntryMinimalToJSONTyped(json, false);
}

export function EntryMinimalToJSONTyped(value?: EntryMinimal | null, ignoreDiscriminator: boolean = false): any {
  if (value == null) {
    return value;
  }

  return {
    id: value['id'],
    title: value['title'],
    date: value['date'].toISOString().substring(0, 10),
    extract: value['extract'],
    wordCount: value['wordCount'],
    charCount: value['charCount'],
    createdAt: value['createdAt'].toISOString(),
    tags: (value['tags'] as Array<any>).map(EntryTagToJSON),
    series: EntrySeriesToJSON(value['series']),
  };
}
