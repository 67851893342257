/* tslint:disable */
/* eslint-disable */
/**
 * My App API
 * My App API description
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 *
 * @export
 * @interface Tag
 */
export interface Tag {
  /**
   *
   * @type {number}
   * @memberof Tag
   */
  id?: number;
}

/**
 * Check if a given object implements the Tag interface.
 */
export function instanceOfTag(value: object): value is Tag {
  return true;
}

export function TagFromJSON(json: any): Tag {
  return TagFromJSONTyped(json, false);
}

export function TagFromJSONTyped(json: any, ignoreDiscriminator: boolean): Tag {
  if (json == null) {
    return json;
  }
  return {
    id: json['id'] == null ? undefined : json['id'],
  };
}

export function TagToJSON(json: any): Tag {
  return TagToJSONTyped(json, false);
}

export function TagToJSONTyped(value?: Tag | null, ignoreDiscriminator: boolean = false): any {
  if (value == null) {
    return value;
  }

  return {
    id: value['id'],
  };
}
