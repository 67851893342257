import { EntryProvider } from '../EntryScreen/EntryContext.tsx';
import { EntryEdit } from '../EntryScreen/EntryEdit';
import Page from '../Page';
import PageTitle from '../PageTitle';

export default function NewEntryScreen() {
  return (
    <Page maxWidth="xl" title={<PageTitle title="New Entry" />}>
      <EntryProvider id={null}>
        <EntryEdit />
      </EntryProvider>
    </Page>
  );
}
